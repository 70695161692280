import React, { useCallback, useMemo, useState } from 'react';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Controller } from 'react-hook-form';
import { UpdatableFieldProps } from 'global/component/UpdatableField/UpdatableField.types';
import { CurrencyTextField, ErrorAdornment, PhoneNumberInput, SelectFromDict } from 'global/component';

const UpdatableField = ({
    register,
    label,
    required,
    error,
    placeholder,
    formRef,
    mode,
    control,
    name,
    selectDictMode,
    defaultValueForDictMode,
    queryParams,
    disabled,
    clearFieldsOnChange,
    resetField,
    clearAllDataOnQuery,
    readOnly,
    queryRequired,

    showErrors
}: UpdatableFieldProps) => {
    const [showSubmit, setShowSubmit] = useState<boolean>(false);

    const onSubmit = useCallback(() => {
        if (showSubmit) {
            setShowSubmit(false);
            if (showErrors) {
                showErrors();
            }
            formRef?.current?.requestSubmit();
        } else {
            setShowSubmit(true);
        }
    }, [showSubmit, formRef?.current?.requestSubmit]);

    const updatableIcons = useMemo(
        () => ({
            readOnly: !showSubmit,
            endAdornment: (
                <>
                    {!readOnly && (
                        <InputAdornment position={'end'} onClick={onSubmit} className={'pointer'}>
                            {showSubmit && <SaveIcon />}
                            {!showSubmit && <BorderColorIcon />}
                        </InputAdornment>
                    )}
                    <ErrorAdornment fieldError={error} />
                </>
            )
        }),
        [showSubmit, onSubmit, error, readOnly]
    );

    const infoPlaceholder = useMemo(() => (!showSubmit ? 'Click on Pen to edit' : undefined), [showSubmit]);

    if (mode === 'text') {
        return (
            <FormControl fullWidth={true}>
                <TextField
                    variant={'outlined'}
                    label={label}
                    placeholder={infoPlaceholder ?? placeholder}
                    required={required}
                    error={!!error}
                    InputProps={updatableIcons}
                    {...register}
                />
            </FormControl>
        );
    }

    if (mode === 'phone') {
        return (
            <FormControl fullWidth={true}>
                <Controller
                    name={name || ''}
                    control={control}
                    render={({ field }) => (
                        <PhoneNumberInput
                            {...field}
                            ref={field.ref}
                            error={!!error}
                            InputProps={updatableIcons}
                            label={label}
                            required={required}
                            placeholder={infoPlaceholder ?? placeholder}
                            fullWidth={true}
                            size={'small'}
                            margin={'normal'}
                        />
                    )}
                />
            </FormControl>
        );
    }

    if (mode === 'selectDict' && !!name) {
        return (
            <FormControl fullWidth={true}>
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <SelectFromDict
                            onBlur={() => field.onBlur()}
                            onChange={(value) => {
                                if (clearFieldsOnChange) {
                                    clearFieldsOnChange.forEach((f) => {
                                        if (resetField) {
                                            resetField(f, {
                                                defaultValue: null
                                            });
                                        }
                                    });
                                }
                                field.onChange(value ?? null);
                            }}
                            placeholder={infoPlaceholder ?? placeholder}
                            label={label}
                            mode={selectDictMode || 'none'}
                            selectAllOption={false}
                            value={field.value}
                            required={required}
                            errors={error}
                            inputProps={updatableIcons}
                            queryParams={queryParams}
                            disabled={disabled}
                            defaultValues={defaultValueForDictMode}
                            clearAllDataOnQuery={clearAllDataOnQuery}
                            queryRequired={queryRequired}
                        />
                    )}
                />
            </FormControl>
        );
    }

    if (mode === 'currency') {
        return (
            <FormControl fullWidth={true}>
                <Controller
                    name={name || ''}
                    control={control}
                    render={({ field }) => (
                        <CurrencyTextField
                            onBlur={() => field.onBlur()}
                            onChange={(val) => field.onChange(val)}
                            label={label}
                            value={field.value}
                            required={required}
                            placeholder={infoPlaceholder ?? placeholder}
                            InputProps={updatableIcons}
                        />
                    )}
                />
            </FormControl>
        );
    }

    if (mode === 'number') {
        return (
            <FormControl fullWidth={true}>
                <Controller
                    name={name || ''}
                    control={control}
                    render={({ field: { onChange, value, ...rest } }) => (
                        <TextField
                            {...rest}
                            onChange={(e) => onChange(+e.target.value)}
                            type={'number'}
                            variant={'outlined'}
                            label={label}
                            placeholder={infoPlaceholder ?? placeholder}
                            required={required}
                            value={value}
                            InputProps={updatableIcons}
                        />
                    )}
                />
            </FormControl>
        );
    }

    return <span />;
};

export default UpdatableField;
