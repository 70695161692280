import './CriteriaForMembers.scss';
import { CriteriaBaseProps } from 'global/component/Criteria/Criteria.types';
import { CriteriaMembers } from 'global/component/Criteria/CriteriaForMembers/CriteriaForMembers.types';
import React, { useCallback, useMemo } from 'react';
import { getSearchIconName } from 'global/globalRender';
import { FormControl, TextField } from '@mui/material';
import SelectFromDict from 'global/component/SelectFromDict/SelectFromDict';
import { langModelActions } from 'reduxProvider';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

const CriteriaForMembers = ({ criteria, onCriteriaChanged }: CriteriaBaseProps) => {
    const { spaModel } = langModelActions.selector();
    const castedCriteria = criteria as CriteriaMembers;

    const onNameChanged = useCallback(
        (arg: React.ChangeEvent<HTMLInputElement>) => {
            const name = arg.target.value || undefined;
            if (onCriteriaChanged && castedCriteria.name !== name) {
                onCriteriaChanged({
                    ...criteria,
                    name
                });
            }
        },
        [onCriteriaChanged, criteria]
    );
    const onRoleChanged = useCallback(
        (newValue: string | undefined) => {
            if (onCriteriaChanged && castedCriteria.roleNames !== newValue) {
                onCriteriaChanged({
                    ...criteria,
                    roleNames: newValue ? [newValue] : undefined
                });
            }
        },
        [onCriteriaChanged, criteria]
    );

    const searchIcon = useMemo(() => getSearchIconName(), []);

    return (
        <>
            <FormControl fullWidth={true}>
                <TextField
                    label={spaModel[LangAliasEnum.NAME_LABEL]}
                    InputProps={searchIcon}
                    placeholder={spaModel[LangAliasEnum.SEARCH_BY_NAME_PLACEHOLDER]}
                    variant={'outlined'}
                    value={castedCriteria.name || ''}
                    onChange={onNameChanged}
                />
            </FormControl>
            <FormControl fullWidth={true}>
                <SelectFromDict
                    mode={'roleNames'}
                    label={spaModel[LangAliasEnum.ROLE_LABEL]}
                    selectAllOption={true}
                    value={castedCriteria.roleNames ? castedCriteria.roleNames[0] : undefined}
                    onChange={onRoleChanged}
                />
            </FormControl>
        </>
    );
};

export default CriteriaForMembers;
