import { Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';

const SPAN_STYLES = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

const RenderExpandableCell = ({ value }: GridRenderCellParams) => {
    const [isOverflowed, setIsOverflow] = useState(false);

    const textElementRef = useRef<HTMLSpanElement | null>(null);

    const checkOverflow = () => {
        // Using getBoundingClientRect, instead of scrollWidth and clientWidth, to get width with fractional accuracy
        const clientWidth = textElementRef.current!.getBoundingClientRect().width;

        textElementRef.current!.style.overflow = 'visible';
        const contentWidth = textElementRef.current!.getBoundingClientRect().width;
        textElementRef.current!.style.overflow = 'hidden';

        setIsOverflow(contentWidth > clientWidth);
    };

    useEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, []);

    return (
        <Tooltip title={value} disableHoverListener={!isOverflowed}>
            <span ref={textElementRef} style={SPAN_STYLES}>
                {value}
            </span>
        </Tooltip>
    );
};

export default RenderExpandableCell;
