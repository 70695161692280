import React from 'react';
import { InputAdornment } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const InfoAdornment = ({ msg }: { msg: string }) => (
    <InputAdornment position={'end'}>
        <Tooltip title={msg} arrow={true}>
            <InfoIcon />
        </Tooltip>
    </InputAdornment>
);

export default InfoAdornment;
