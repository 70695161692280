import { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import useRequestService from 'utils/RequestService/RequestService';
import { AxiosType } from 'utils/AxiosService/AxiosService.types';
import { Credentials, IAuthenticationService } from './AuthenticationService.types';

const useAuthenticationService = (): IAuthenticationService => {
    const { post } = useRequestService(true, true, false);

    const loginBySite = useCallback((credentials: Credentials): Promise<AxiosResponse> => {
        const credentialsBase64 = window.btoa(`${credentials.email}:${credentials.password}`);

        return post(AxiosType.ANON, '/signIn', {}, undefined, {
            Authorization: `Basic ${credentialsBase64}`
        });
    }, []);

    const logout = useCallback(() => post(AxiosType.AUTH, '/logout', {}), []);

    // TODO: implement forget pwd
    /* const forgetPwd = (email: string) => {}; */

    return useMemo(
        () => ({
            loginBySite,
            logout
        }),
        [loginBySite, logout]
    );
};

export default useAuthenticationService;
