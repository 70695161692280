import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

export type Replacement = {
    replacement: string;
    value: string;
};

export type IndexableObject<T> = {
    [key: string]: T;
};

export enum ClientProjectStatusEnum {
    RETURN_PENDING = 'Return Pending',
    RETURN_APPROVED = 'Return Approved',
    RETURN_DECLINED = 'Return Declined'
}

export enum UserRolesEnum {
    Administrator = 'Administrator',
    Client = 'Client'
}

export enum BackEnumType {
    Product = 'PRODUCT_TYPE',
    LeadSource = 'LEAD_SOURCE_TYPE',
    Roles = 'ROLE_NAME_TYPE',
    RefundReason = 'REFUND_REASON_TYPE',
    Industry = 'INDUSTRY_TYPE'
}

export enum PageNameEnum {
    MyProfile = 'MyProfile',

    LeadInfo = 'LeadInfo',
    LeadNew = 'LeadNew',
    LeadEdit = 'LeadEdit',

    MemberInfo = 'MemberInfo',
    MemberNew = 'MemberNew',
    MemberEdit = 'MemberEdit',

    Statistic = 'Statistic'
}

export enum Bundle {
    ANONYMOUS = 'auth',
    ADMIN = 'admin',
    CLIENT = 'client'
}

export type Paging = {
    page: number;
    pageSize?: number;
};

export type PageUrl = {
    id: string;
    name: string;
    aliasName: LangAliasEnum;
    label?: string;
    routingUrl: string;
    redirectUrl: string;
    children?: Array<PageUrl>;
    role: 'all' | Array<UserRolesEnum>;
    UrlIcon?: React.ElementType;
    Component?: React.ComponentType<any>;
    replacements?: Array<Replacement>;
    inMenu?: boolean;
    isFooter?: boolean;
    getGuidBold?: (params?: IndexableObject<string>) => string;
};

export type BundleUrls = {
    LEAD: {
        NEW: string;
        LIST: string;
        EDIT: string;
        MEMO: string;
    };
    PROFILE: {
        MY: string;
    };
};

export enum PagesEnum {}
