import { Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Menu as ProMenu, MenuItem as ProMenuItem, Sidebar } from 'react-pro-sidebar';
import { contextActions } from 'reduxProvider';
import { useSecurityValidator } from 'utils';
import { Bundle, PageUrl } from 'index.types';
import MenuItemsRender from './MenuItemsRender';

const LeftMenu = ({
    activeGuid,
    toggleSidebar,
    inMenuPages,
    bundle
}: {
    activeGuid: string | undefined;
    toggleSidebar: boolean;
    inMenuPages: Array<PageUrl>;
    bundle: Bundle;
}) => {
    const { user } = contextActions.selector();
    const { isRoleValid } = useSecurityValidator();

    const [collapleSidebar, setCollapleSidebar] = useState<boolean>(true);

    useEffect(() => {
        setCollapleSidebar(true);
    }, []);

    const pagesListSecurity = useMemo(() => inMenuPages.filter(({ role }) => isRoleValid(role)), [user]);

    return (
        <Sidebar width={'250px'} collapsed={collapleSidebar} toggled={toggleSidebar}>
            <div className={'menu-container'}>
                <ProMenu className={'padding-top-for-first-element'} closeOnClick={true}>
                    <ProMenuItem
                        icon={
                            <img
                                className={'brand-svg'}
                                alt={'brand-logo'}
                                src={'/assets/brand/brand-logo.svg'}
                            />
                        }
                        onClick={() => setCollapleSidebar((prev) => !prev)}
                        className={'pro-menu-item menu-item-brand-logo'}
                    >
                        <img
                            className={'brand-name'}
                            alt={'brand-name'}
                            src={'/assets/brand/brand-name.svg'}
                        />
                    </ProMenuItem>
                </ProMenu>
                {false && (
                    <ProMenu className={'text-menu-indent'} closeOnClick={true}>
                        <ProMenuItem
                            className={'burger-menu-container'}
                            component={<span className={'burger-menu-outliner'} />}
                        >
                            <span className={'text-menu'}>
                                <Typography variant={'body2'}>Pages</Typography>
                            </span>
                        </ProMenuItem>
                    </ProMenu>
                )}
                <ProMenu className={'text-menu-indent'} closeOnClick={true}>
                    <MenuItemsRender
                        bundle={bundle}
                        routingMapping={pagesListSecurity}
                        activeGuid={activeGuid}
                    />
                </ProMenu>
            </div>
        </Sidebar>
    );
};

export default LeftMenu;
