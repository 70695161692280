import { object, string } from 'zod';
import { matchIsValidTel } from 'mui-tel-input';
import { LocalitySchema } from '../locality';

export default object({
    id: string(),
    description: string().nullish(),
    email: string().min(1, 'Se requiere correo electrónico').email('El correo electrónico no es válido'),
    familyName: string().min(1, 'Se requiere apellido'),
    faxNumber: string().nullish(),
    givenName: string().min(1, 'Se requiere nombre'),
    phoneNumber: string({
        required_error: 'Se requiere número de teléfono',
        invalid_type_error: 'Se requiere número de teléfono'
    })
        .min(1, 'Se requiere número de teléfono')
        .refine(matchIsValidTel, 'El número de teléfono no es válido'),
    picture: string().nullish(),
    addressLineOne: string().min(1, 'Dirección Se requiere la línea 1').nullish(),
    addressLineTwo: string().nullish(),
    locality: LocalitySchema
});
