import { Autocomplete, Box, FilterOptionsState, TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import {
    SelectWithSearchOption,
    SelectWithSearchProps
} from 'global/component/SelectWithSearch/SelectWithSearch.types';
import { getInputPropsForSelect } from 'global/globalRender';

const SelectWithSearch = ({
    options,
    onChange,
    onBlur,
    value,
    label,
    loading,
    open,
    onOpen,
    onClose,
    required,
    errors,
    allowMultipleValues,
    inputProps,
    disabled,
    readonly,
    filterSelectedOptions,
    excludeValues,
    placeholder
}: SelectWithSearchProps) => {
    const isReadOnly = useMemo(() => {
        if (readonly) {
            return true;
        }

        return inputProps ? inputProps.readOnly : false;
    }, [readonly, inputProps?.readOnly]);

    const filterOptions = useCallback(
        (
            nonFilteredOptions: Array<SelectWithSearchOption>,
            state: FilterOptionsState<SelectWithSearchOption>
        ): Array<SelectWithSearchOption> => {
            const { inputValue } = state;
            const filterByNameOptions = nonFilteredOptions.filter(
                (val) => inputValue === '' || val.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            );
            if (!excludeValues) return filterByNameOptions;
            return filterByNameOptions.filter((val) => excludeValues.indexOf(val.value) === -1);
        },
        [excludeValues]
    );

    return (
        <Autocomplete
            multiple={allowMultipleValues}
            filterSelectedOptions={filterSelectedOptions}
            fullWidth={true}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            options={options}
            loading={!!loading}
            readOnly={isReadOnly}
            disabled={disabled}
            filterOptions={(opts, state) => filterOptions(opts, state)}
            isOptionEqualToValue={(option, val) => option.value === val?.value}
            getOptionLabel={(option: SelectWithSearchOption) => (option ? option.name : 'undefined')}
            autoHighlight={true}
            renderInput={(params) => {
                const InputProps = useMemo(
                    () => getInputPropsForSelect(params, readonly, errors, inputProps, loading),
                    [params, readonly, errors, inputProps, loading]
                );

                return (
                    <TextField
                        {...params}
                        label={label}
                        required={required}
                        error={!!errors}
                        placeholder={placeholder}
                        InputProps={InputProps}
                    />
                );
            }}
            renderOption={(props, option: SelectWithSearchOption) => (
                <Box component={'li'} {...props} key={option.value as string}>
                    {option.name}
                </Box>
            )}
            size={'small'}
            onChange={(_event: unknown, val: SelectWithSearchOption | SelectWithSearchOption[] | null) =>
                onChange(val)
            }
            onBlur={onBlur ?? undefined}
            value={value ?? null}
        />
    );
};

export default SelectWithSearch;
