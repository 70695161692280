import { lazily } from 'react-lazily';
import { guidGenerator } from 'global/globalFunctions';
import { PageUrl } from 'index.types';
import { MEMBER_PAGES_URLS, MEMBER_PARAMS, MEMBER_SECURITY, MEMBER_URLS } from 'admin/Member/index.url';
import PeopleIcon from '@mui/icons-material/People';
import { withSecurity } from 'global/abstraction';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

const { MemberInfo, MemberManipulation } = lazily(() => import('./index'));

const MEMBER_GUID = guidGenerator();

const MemberUrl: PageUrl = {
    id: MEMBER_GUID,
    name: 'Members',
    label: 'Members',
    aliasName: LangAliasEnum.MEMBERS_TITLE,
    redirectUrl: MEMBER_PAGES_URLS.LIST,
    routingUrl: MEMBER_URLS.MEMBER_LIST,
    inMenu: true,
    role: 'all',
    Component: withSecurity(MEMBER_SECURITY.INFO, MemberInfo),
    UrlIcon: PeopleIcon,
    getGuidBold: () => MEMBER_GUID
};
const MemberEditUrl: PageUrl = {
    id: guidGenerator(),
    name: 'Edit member',
    aliasName: LangAliasEnum.EDIT_MEMBER_TITLE,
    role: 'all',
    redirectUrl: MEMBER_PAGES_URLS.EDIT,
    routingUrl: `${MEMBER_URLS.MEMBER_EDIT}/:${MEMBER_PARAMS.ID_PARAM}`,
    replacements: [
        {
            replacement: MEMBER_PARAMS.ID_REPLACEMENT_PARAM,
            value: MEMBER_PARAMS.ID_PARAM
        }
    ],
    Component: withSecurity(MEMBER_SECURITY.EDIT, MemberManipulation),
    getGuidBold: () => MemberUrl.id
};
const MemberNewUrl: PageUrl = {
    id: guidGenerator(),
    name: 'New Member',
    aliasName: LangAliasEnum.NEW_MEMBER_TITLE,
    role: 'all',
    redirectUrl: MEMBER_PAGES_URLS.NEW,
    routingUrl: MEMBER_URLS.MEMBER_NEW,
    Component: withSecurity(MEMBER_SECURITY.NEW, MemberManipulation),
    getGuidBold: () => MemberUrl.id
};
const MemberPages: PageUrl = {
    id: guidGenerator(),
    name: 'Members',
    aliasName: LangAliasEnum.MEMBERS_TITLE,
    role: 'all',
    redirectUrl: `${MEMBER_PARAMS.GENERAL_URL}`,
    routingUrl: `${MEMBER_PARAMS.GENERAL_URL}`,
    inMenu: true,
    children: [MemberUrl, MemberEditUrl, MemberNewUrl]
};

export { MemberPages, MemberUrl, MemberEditUrl, MemberNewUrl };
