export const ACCESS_TOKEN = 'Session_ID';
export const XSRF_COOKIE_NAME = 'XSRF-TOKEN';
export const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';
export const USER_TYPE = 'X-User-Type';
export const GlobalPermissionsConstant = {
    VIEW: 'view',
    EDIT: 'edit_all',
    CREATE: 'create',
    DELETE: 'delete'
};

export enum UserSecurityTypeEnum {
    ADMIN = 'ADMINISTRATOR',
    CLIENT = 'CLIENT'
}
