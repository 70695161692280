import { contextActions, langModelActions, useAppDispatch } from 'reduxProvider';
import { useNavigate } from 'react-router';
import { AxiosType, useAuthenticationService, useRequestService } from 'utils';
import React, { useCallback, useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { userFunctions, UserType } from 'pojo/user';
import { clearCookies } from 'global/globalFunctions';
import { Avatar, Divider, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { ProfileType } from 'pojo/profile';
import { Logout, Settings } from '@mui/icons-material';
import { SelectButton, UserWithAvatar } from 'global/component';
import { Bundle } from 'index.types';
import { COMMON_PAGES_URL } from 'common/index.url';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';
import { SETTINGS_SX } from './Header.styles';

const Header = ({
    isLogout,
    aliasName,
    bundle
}: {
    aliasName: LangAliasEnum | undefined;
    bundle: Bundle;
    isLogout: (logout: boolean) => void;
}) => {
    const { spaModel } = langModelActions.selector();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { user } = contextActions.selector();
    const { post } = useRequestService(true, true, false, { basePath: '/user' });
    const { logout } = useAuthenticationService();

    const onChangeActiveProfile = useCallback((profileId: string) => {
        post(AxiosType.AUTH, '/changeActiveProfile', { profileId }).then((resp: AxiosResponse) => {
            if (resp.data) {
                contextActions.applyUserContext(dispatch, resp.data as UserType);
            }
        });
    }, []);
    const onAccountSettingsClick = useCallback(() => {
        const url = COMMON_PAGES_URL.PROFILE.MY;
        navigate(`/${bundle}/${url}`);
    }, [bundle]);
    const onLogout = useCallback(() => {
        logout().then(() => {
            clearCookies();
            contextActions.logout(dispatch);
            isLogout(true);
        });
    }, []);

    const userFullName = useMemo(() => userFunctions.getFullName(user), [user]);
    const userActiveProfile = useMemo(() => userFunctions.getDefaultRole(user), [user]);

    return (
        <div className={'header'}>
            <Typography variant={'h5'} component={'h5'}>
                {aliasName ? spaModel[aliasName] : ''}
            </Typography>
            <SelectButton
                title={'Account settings'}
                mode={'icon-button'}
                menuItems={
                    <div>
                        {[user.profile].map(({ isActive, id, role: { name } }: ProfileType) => {
                            if (!isActive) {
                                return (
                                    <MenuItem key={id} onClick={() => onChangeActiveProfile(id)}>
                                        <Avatar />
                                        <Typography>{name}</Typography>
                                    </MenuItem>
                                );
                            }

                            return null;
                        })}
                        {false && <Divider />}
                        <MenuItem onClick={onAccountSettingsClick}>
                            <ListItemIcon>
                                <Settings sx={SETTINGS_SX} fontSize={'small'} />
                            </ListItemIcon>
                            <Typography>{spaModel[LangAliasEnum.MY_ACCOUNT_BUTTON]}</Typography>
                        </MenuItem>
                        <MenuItem onClick={onLogout}>
                            <ListItemIcon>
                                <Logout sx={SETTINGS_SX} fontSize={'small'} />
                            </ListItemIcon>
                            <Typography>{spaModel[LangAliasEnum.LOGOUT_BUTTON]}</Typography>
                        </MenuItem>
                    </div>
                }
            >
                <UserWithAvatar
                    divClassName={'full-name-container'}
                    fullName={userFullName}
                    picture={user.person?.picture}
                    displayRole={true}
                    role={userActiveProfile.name}
                    fullNameVariant={'h6'}
                    fullNameComponent={'h6'}
                    fullNameClassName={'user-full-name'}
                />
            </SelectButton>
        </div>
    );
};

export default Header;
