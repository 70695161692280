import { PageCriteriaMemoService } from 'utils/PageCriteriaMemoService/PageCriteriaMemoService.types';
import { useNavigate } from 'react-router';
import { useCallback, useMemo } from 'react';
import { PagingCriteria } from 'global/component/Criteria/Criteria.types';

const usePageCriteriaMemo = (
    memoKey: string,
    onChangeCriteria: (newCriteria: object) => void,
    getDefaultCriteria?: () => object
): PageCriteriaMemoService => {
    const navigate = useNavigate();

    const loadPageState = useCallback((): object | null => {
        const state = sessionStorage.getItem(`${memoKey}_state`);
        if (state) {
            return JSON.parse(state);
        }
        return null;
    }, [memoKey]);

    const savePageState = useCallback(
        (obj: object) => {
            sessionStorage.setItem(`${memoKey}_state`, JSON.stringify(obj));
        },
        [memoKey]
    );

    const handleCriteriaMemo = useCallback(() => {
        const possibleCriteria = loadPageState();
        onChangeCriteria(possibleCriteria || (getDefaultCriteria ? getDefaultCriteria() : {}));
    }, [memoKey, loadPageState, onChangeCriteria, getDefaultCriteria]);

    const navigateWithMemo = useCallback(
        (toUrl: string, criteria: PagingCriteria) => {
            savePageState(criteria);
            navigate(toUrl);
        },
        [memoKey, savePageState]
    );

    return useMemo(
        () => ({
            navigateWithMemo,
            handleCriteriaMemo
        }),
        [navigateWithMemo, handleCriteriaMemo]
    );
};

export default usePageCriteriaMemo;
