import { Bundle, PageUrl } from 'index.types';
import { LayoutProps } from 'common/Layout/Layout.types';
import { ClientAuthGroupPages, InMenuPages } from './index.routing';

const GroupPages: Array<PageUrl> = [ClientAuthGroupPages];
const LayoutConfig: LayoutProps = {
    bundle: Bundle.CLIENT,
    inMenuPages: InMenuPages,
    mainBundleGroup: GroupPages
};

export { GroupPages, LayoutConfig, ClientAuthGroupPages };
