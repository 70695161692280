import { GridRenderCellParams } from '@mui/x-data-grid';
import { userFunctions, UserType } from 'pojo/user';
import React from 'react';
import CustomerInfoCell from 'utils/GridColumnsService/columns/common/CustomerInfoCell/CustomerInfoCell';
import { ProfileType } from 'pojo/profile';
import { DataGridColumn } from 'global/component/CustomDataGrid/CustomDataGrid.types';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

const MEMBERS_GRID_COLUMNS = [
    {
        field: 'person',
        headerName: 'Member Info',
        aliasName: LangAliasEnum.MEMBER_INFO_COLUMN_TITLE,
        flex: 0.8,
        minWidth: 200,
        align: 'left',
        headerAlign: 'left',
        valueGetter: (_, row) => {
            const member = row as UserType;
            return {
                fullName: userFunctions.getFullName(member),
                createdAt: member.createdAt
            };
        },
        renderCell: (params: GridRenderCellParams) => {
            const { fullName, createdAt } = params.value as { fullName: string; createdAt: string };
            return <CustomerInfoCell fullName={fullName} createdAt={createdAt} />;
        }
    },
    {
        field: 'profile',
        headerName: 'Role',
        aliasName: LangAliasEnum.ROLE_LABEL,
        width: 250,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (value) => {
            const profile = value as ProfileType;
            return profile.role.name;
        }
    }
] as Array<DataGridColumn>;

export default MEMBERS_GRID_COLUMNS;
