import useSecurityValidator from 'utils/SecurityValidatorService/SecurityValidatorService';
import React, { useCallback } from 'react';
import { MenuItem as ProMenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { Bundle, PageUrl } from 'index.types';
import { langModelActions } from 'reduxProvider';

const MenuItemsRender = ({
    routingMapping,
    activeGuid,
    bundle
}: {
    routingMapping: Array<PageUrl>;
    activeGuid: string | undefined;
    bundle: Bundle;
}) => {
    const { spaModel } = langModelActions.selector();
    const { isRoleValid } = useSecurityValidator();

    const transformRedirectUrl = useCallback(
        (commonLink: string): string => `/${bundle}/${commonLink}`,
        [bundle]
    );

    return (
        <>
            {routingMapping.map(
                ({ redirectUrl, aliasName, UrlIcon, children, id, inMenu, role }: PageUrl) => {
                    if (children) {
                        if (inMenu && isRoleValid(role)) {
                            return (
                                <MenuItemsRender
                                    key={id}
                                    bundle={bundle}
                                    activeGuid={activeGuid}
                                    routingMapping={children}
                                />
                            );
                        }

                        return <React.Fragment key={id} />;
                    }

                    if (inMenu && isRoleValid(role)) {
                        return (
                            <ProMenuItem
                                key={id}
                                className={`pro-menu-item${activeGuid === id ? ' active' : ''}`}
                                component={<Link to={transformRedirectUrl(redirectUrl)} />}
                                icon={<Avatar>{UrlIcon && <UrlIcon />}</Avatar>}
                            >
                                {spaModel[aliasName]}
                            </ProMenuItem>
                        );
                    }
                    return <React.Fragment key={id} />;
                }
            )}
        </>
    );
};

export default MenuItemsRender;
