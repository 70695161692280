import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import AutoNumeric from 'autonumeric';
import { InputAdornment, TextField } from '@mui/material';
import { ErrorAdornment } from 'global/component/index';
import { CurrencyTextFieldProps } from './CurrencyTextField.types';

const CurrencyTextField = React.forwardRef(
    (
        {
            errors,
            value,
            onChange,
            InputProps,
            readonly,
            defaultValue,
            isStatic,
            ...props
        }: CurrencyTextFieldProps & {
            isStatic?: boolean;
        },
        ref
    ) => {
        const innerRef = useRef<unknown>(ref);
        const autoNumericRef = useRef<AutoNumeric | null>(null);

        const initFunction = useCallback(
            (val: unknown) => {
                autoNumericRef.current = new AutoNumeric(
                    innerRef.current as string | HTMLInputElement | HTMLElement,
                    Number(val) || null,
                    {
                        ...AutoNumeric.getPredefinedOptions(),
                        ...props
                    }
                );

                return () => {
                    autoNumericRef?.current?.remove();
                };
            },
            [autoNumericRef.current]
        );
        useEffect(() => initFunction(value), []);
        useEffect(() => {
            if (isStatic) {
                autoNumericRef.current?.set(defaultValue as number);
            }
        }, [defaultValue]);

        const getValue = useCallback(() => {
            if (!autoNumericRef.current) return undefined;
            return autoNumericRef.current.getNumber();
        }, [autoNumericRef.current]);
        const onChanged = useCallback(() => {
            if (onChange) {
                onChange(getValue());
            }
        }, [onChange]);

        const chargeInputProps = useMemo(
            () => ({
                readOnly: !!readonly,
                startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
                endAdornment: <ErrorAdornment fieldError={errors} />,
                ...InputProps
            }),
            [readonly, errors, InputProps]
        );

        return (
            <TextField
                inputRef={innerRef}
                InputProps={chargeInputProps}
                onChange={onChanged}
                error={!!errors}
                defaultValue={defaultValue}
                {...props}
            />
        );
    }
);

export default CurrencyTextField;
