import { useCallback, useMemo } from 'react';

const { REACT_APP_API } = process.env;

const useUrlBuilder = () => {
    const urlBuild = useCallback((path: string) => `${REACT_APP_API}${path}`, []);

    return useMemo(() => ({ urlBuild }), [urlBuild]);
};

export default useUrlBuilder;
