import { createTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';

// TODO validate all schema
const theme = createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(',')
    },
    palette: {
        success: {
            main: 'color(srgb 0.012 0.3984 0.3994)'
        }
    },
    components: {
        MuiSwitch: {
            defaultProps: {
                color: 'success'
            }
        },
        MuiIconButton: {
            defaultProps: {
                sx: {
                    color: 'color(srgb 0.012 0.3984 0.3994)'
                }
            }
        },
        MuiTabs: {
            defaultProps: {
                sx: {
                    borderBottom: 1,
                    borderColor: 'divider'
                }
            },
            styleOverrides: {
                indicator: {
                    backgroundColor: 'color(srgb 0.012 0.3984 0.3994)'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#fff1f0',
                        color: 'color(srgb 0.012 0.3984 0.3994)'
                    }
                },
                textColorPrimary: {
                    color: '#000'
                }
            }
        },
        MuiAvatar: {
            defaultProps: {
                sx: {
                    width: 30,
                    height: 30,
                    backgroundColor: 'color(srgb 0.012 0.3984 0.3994)'
                }
            }
        },
        MuiButton: {
            defaultProps: {
                size: 'medium',
                color: 'success'
            }
        },
        MuiTextField: {
            defaultProps: {
                size: 'small',
                fullWidth: true,
                margin: 'normal'
            }
        },
        MuiCircularProgress: {
            defaultProps: {
                color: 'success'
            }
        },
        MuiLinearProgress: {
            defaultProps: {
                color: 'success'
            }
        },
        MuiBackdrop: {
            defaultProps: {
                sx: {
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',

                    zIndex: (th: Theme) => th.zIndex.drawer + 1
                }
            }
        }
    }
});

export default theme;
