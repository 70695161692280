import moment from 'moment/moment';
import React, { useMemo } from 'react';
import './CustomerInfoCell.scss';
import { DATE_TIME_FORMAT } from 'global/globalFormatters';
import { langModelActions } from 'reduxProvider';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';
import { CustomerInfoCellProps } from './CustomerInfoCell.types';

const CustomerInfoCell = ({ fullName, createdAt }: CustomerInfoCellProps) => {
    const { spaModel } = langModelActions.selector();
    const creationDate = useMemo(() => moment(createdAt).format(DATE_TIME_FORMAT), [createdAt]);

    return (
        <div className={'lead-cell'}>
            <span className={'lead-full-name'}>{fullName}</span>
            <span className={'lead-created-date'}>
                {spaModel[LangAliasEnum.CREATION_DATE_LABEL]}: {creationDate}
            </span>
        </div>
    );
};

export default CustomerInfoCell;
