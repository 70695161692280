import React, { useMemo } from 'react';
import { Avatar, Typography } from '@mui/material';
import { stringAvatar } from 'global/globalFunctions';
import { UserWithAvatarProps } from './UserWithAvatar.types';

const UserWithAvatar = ({
    picture,
    fullName,
    displayFullName,
    divClassName,
    displayRole,
    role,
    fullNameClassName,
    fullNameVariant,
    fullNameComponent
}: UserWithAvatarProps) => {
    const strWithAvatar = useMemo(() => stringAvatar(fullName), [fullName]);

    return (
        <>
            {!picture && <Avatar {...strWithAvatar} />}
            {picture && <Avatar src={picture} />}
            <div className={divClassName}>
                <Typography
                    variant={fullNameVariant || 'body2'}
                    component={fullNameComponent || 'span'}
                    className={fullNameClassName || 'user-full-name'}
                >
                    {displayFullName || fullName}
                </Typography>
                {displayRole && <Typography className={'user-current-role'}>{role}</Typography>}
            </div>
        </>
    );
};

export default UserWithAvatar;
