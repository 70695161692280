import { DataGridActionProps } from 'utils/GridColumnsService/GridColumnsService.types';
import { IconButton } from '@mui/material';
import React from 'react';

const CrudIconButton = ({ label, onClick, Icon }: DataGridActionProps) => (
    <IconButton size={'medium'} aria-label={label} onClick={onClick}>
        {Icon && <Icon fontSize={'inherit'} />}
    </IconButton>
);

export default CrudIconButton;
