import React from 'react';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import './ListViewMode.scss';
import { ListViewModeProps } from 'global/component/CustomDataGrid/ListViewMode/ListViewMode.types';
import { guidGenerator } from 'global/globalFunctions';

const ListViewMode = ({
    apiRef,
    data,
    columns,
    onRowClick,
    rowCount,
    columnVisibilityModel,
    onPaginationModelChanged
}: ListViewModeProps) => {
    const gridRef = apiRef ?? useGridApiRef();

    return (
        <div className={'list-view-mode'}>
            <DataGrid
                apiRef={gridRef}
                getRowId={(params) => params.id ?? guidGenerator()}
                columns={columns}
                rows={data}
                rowCount={rowCount}
                pagination={true}
                paginationMode={'server'}
                autoPageSize={true}
                autoHeight={false}
                onRowClick={onRowClick}
                columnVisibilityModel={columnVisibilityModel}
                onPaginationModelChange={(model) => {
                    onPaginationModelChanged({
                        page: model.page,
                        pageSize: model.pageSize
                    });
                }}
            />
        </div>
    );
};

export default ListViewMode;
