import { Bundle, PageUrl } from 'index.types';
import { guidGenerator } from 'global/globalFunctions';
import { LeadPages, MyProfileUrl } from 'common/index.routing';
import { MemberPages } from 'admin/Member/index.routing';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

const AdminAuthGroupPages: PageUrl = {
    id: guidGenerator(),
    name: 'Admin pages',
    aliasName: LangAliasEnum.UNUSED,
    redirectUrl: `/${Bundle.ADMIN}`,
    routingUrl: `/${Bundle.ADMIN}`,
    role: 'all',
    children: [LeadPages, MyProfileUrl, MemberPages]
};

const InMenuPages = [LeadPages, MemberPages];

export { AdminAuthGroupPages, InMenuPages };
