import React, { useCallback, useEffect, useState } from 'react';
import useRequestService from 'utils/RequestService/RequestService';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { Navigate } from 'react-router-dom';
import { AxiosType } from 'utils';
import { IndexableObject } from 'index.types';
import { contextActions, langModelActions } from 'reduxProvider';
import { GlobalPermissionsConstant } from 'global/globalSecurity';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';
import { WithSecurityWrapperProps } from './WithSecurityWrapper.types';

const WithSecurityWrapper = React.forwardRef(
    <R, T>({ pageProps, PageComponent, page }: WithSecurityWrapperProps<T>, ref: React.ForwardedRef<R>) => {
        const { spaModel } = langModelActions.selector();
        const { user } = contextActions.selector();
        const { enqueueSnackbar } = useSnackbar();
        const { get } = useRequestService(true, true, false, { basePath: '/internal' }, true);
        const [isRequestFinished, setIsRequestFinished] = useState<boolean>(false);
        const [secData, setSecData] = useState<IndexableObject<boolean>>({});

        const loadSecurity = useCallback(() => {
            get(AxiosType.AUTH, '/security', { page })
                .then((resp: AxiosResponse) => {
                    if (resp.data) {
                        setSecData(resp.data);
                        setIsRequestFinished(true);
                    }
                })
                .catch(() => setIsRequestFinished(false));
        }, [page]);

        useEffect(() => {
            if (isRequestFinished) {
                loadSecurity();
            }
        }, [user.profile]);
        useEffect(() => {
            loadSecurity();
        }, [loadSecurity]);

        const onNoPermissionMsg = useCallback(() => {
            enqueueSnackbar(spaModel[LangAliasEnum.NO_PERMISSION_MSG], { variant: 'warning' });
        }, []);

        if (isRequestFinished) {
            if (secData[GlobalPermissionsConstant.VIEW]) {
                return (
                    <PageComponent
                        ref={ref}
                        {...pageProps}
                        secData={secData}
                        noPermissionMsg={onNoPermissionMsg}
                    />
                );
            }

            return <Navigate to={'/'} />;
        }

        return <span />;
    }
);

export default WithSecurityWrapper;
