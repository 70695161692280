import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { Cookies } from 'react-cookie';
import { ACCESS_TOKEN, USER_TYPE } from 'global/globalSecurity';
import { contextActions } from 'reduxProvider';
import { PageUrl, Replacement } from 'index.types';
import { ANON_PAGES_URLS } from 'anonymous/index.url';
import { userFunctions } from 'pojo/user';
import { Navigate } from 'react-router-dom';
import { clearCookies } from 'global/globalFunctions';
import { LayoutProps } from 'common/Layout/Layout.types';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';
import Header from './components/Header/Header';
import LeftMenu from './components/LeftMenu';
import './Layout.scss';

const COOKIES = new Cookies();

const Layout = ({ mainBundleGroup, inMenuPages, bundle }: LayoutProps) => {
    const params = useParams(); // possible params
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = contextActions.selector();

    const [isLogout, setIsLogout] = useState(false);
    const [alias, setAlias] = useState<LangAliasEnum | undefined>();
    const [activeGuid, setActiveGuid] = useState<string | undefined>();
    const [toggleSidebar, setToggleSidebar] = useState<boolean>(true);

    const getPage = useCallback(
        (
            routingMapping: Array<PageUrl>
        ):
            | {
                  alias: LangAliasEnum;
                  id: string | undefined;
              }
            | undefined => {
            for (const pageUrl of routingMapping) {
                const { redirectUrl, aliasName, getGuidBold, replacements, children } = pageUrl;
                if (children) {
                    const foundPageName = getPage(children);
                    if (foundPageName) {
                        return foundPageName;
                    }
                }

                if (replacements) {
                    let url = redirectUrl;
                    replacements.forEach(({ replacement, value }: Replacement) => {
                        url = url.replace(replacement, params[value] as string);
                    });

                    if (location.pathname === `/${bundle}/${url}`) {
                        return {
                            id: getGuidBold
                                ? getGuidBold(
                                      params as never as {
                                          [param: string]: string;
                                      }
                                  )
                                : undefined,
                            alias: aliasName
                        };
                    }
                }

                if (location.pathname === `/${bundle}/${redirectUrl}`) {
                    return {
                        id: getGuidBold
                            ? getGuidBold(
                                  params as never as {
                                      [param: string]: string;
                                  }
                              )
                            : undefined,
                        alias: aliasName
                    };
                }
            }

            return undefined;
        },
        [params, location.pathname]
    );

    useEffect(() => {
        setToggleSidebar(true);

        const activePage = getPage(mainBundleGroup);
        setAlias(activePage?.alias);
        setActiveGuid(activePage?.id);
    }, [getPage]);
    useEffect(() => {
        if (isLogout) {
            navigate(ANON_PAGES_URLS.AUTH);
        }
    }, [isLogout]);

    if (
        !COOKIES.get(ACCESS_TOKEN) ||
        !COOKIES.get(USER_TYPE) ||
        !user.id ||
        isLogout ||
        userFunctions.getDefaultRole(user).name.toUpperCase() !== COOKIES.get(USER_TYPE)
    ) {
        clearCookies();
        return <Navigate to={ANON_PAGES_URLS.AUTH} />;
    }

    return (
        <div className={'auth-layout'}>
            <LeftMenu
                activeGuid={activeGuid}
                toggleSidebar={toggleSidebar}
                inMenuPages={inMenuPages}
                bundle={bundle}
            />
            <div className={'auth-content'}>
                <Header
                    bundle={bundle}
                    aliasName={alias}
                    isLogout={(isLogoutFinished: boolean) => setIsLogout(isLogoutFinished)}
                />
                <div className={'page-container'}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Layout;
