import React from 'react';
import { LinearProgress } from '@mui/material';

const SuspenseComponent = <T,>({
    Component,
    data
}: {
    Component: React.ElementType;
    data: T | undefined;
}) => (
    <React.Suspense fallback={<LinearProgress />}>
        <Component {...data} />
    </React.Suspense>
);

export default SuspenseComponent;
