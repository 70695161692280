import { object, string } from 'zod';
import { CountrySchema } from 'pojo/country';

export default object({
    id: string({
        invalid_type_error: 'Se requiere región',
        required_error: 'Se requiere región'
    }),
    name: string(),
    code: string(),
    country: CountrySchema
});
