import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SelectWithSearchOption } from 'global/component/SelectWithSearch/SelectWithSearch.types';
import { AxiosType, useRequestService } from 'utils';
import { AxiosResponse } from 'axios';
import SelectWithSearch from 'global/component/SelectWithSearch/SelectWithSearch';
import { BackEnumType } from 'index.types';
import { ControlledResponse, SelectFromDictProps } from './SelectFromDict.types';

const DEFAULT_STATE = [{ name: 'All', value: null }];

const SelectFromDict = ({
    mode,
    onChange,
    onBlur,
    value,
    label,
    selectAllOption,
    queryParams,
    errors,
    required,
    defaultValues,
    allowMultipleValues,
    inputProps,
    disabled,
    dataSource,
    readonly,
    extraFields,
    clearAllDataOnQuery,
    filterSelectedOptions,
    excludeValues,
    placeholder,
    queryRequired,
    onLoaded,
    ignoreOpen
}: SelectFromDictProps) => {
    const prevQueryParams = useRef<string | undefined>(undefined);
    const getDefaultValue = useCallback((): Array<SelectWithSearchOption> => {
        let defValue: Array<SelectWithSearchOption> = [];
        if (defaultValues) {
            defValue = [...defaultValues];
        }

        const localOptions = selectAllOption ? [...defValue, ...DEFAULT_STATE] : [...defValue];
        return dataSource ? [...localOptions, ...dataSource] : localOptions;
    }, [defaultValues, selectAllOption, dataSource]);

    const [open, setOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<Array<SelectWithSearchOption>>(getDefaultValue());
    const loading = !disabled && (ignoreOpen || open) && options.length === 0;
    const { get } = useRequestService(false, true, false);

    const getEndPoint = useCallback(() => {
        switch (mode) {
            case 'region':
                return 'region';
            case 'locality':
                return 'locality';
            case 'lead_source':
                return `/internal/enums?typeName=${BackEnumType.LeadSource}`;
            case 'roleNames':
                return `/internal/enums?typeName=${BackEnumType.Roles}`;
            case 'roles':
                return '/role';
            case 'product':
                return `/internal/enums?typeName=${BackEnumType.Product}`;
            case 'refund_reason':
                return `/internal/enums?typeName=${BackEnumType.RefundReason}`;
            case 'project_status':
                return `/project/statuses?phase=Lead`;
            case 'industry':
                return `/internal/enums?typeName=${BackEnumType.Industry}`;
            case 'custom':
            case 'none':
            default:
                return '';
        }
    }, [mode]);

    const loadData = useCallback(
        (): Promise<void> =>
            (async () => {
                if (mode === 'custom') {
                    setOptions(dataSource ?? []);
                    return;
                }

                get(AxiosType.AUTH, getEndPoint(), queryParams || {}).then((resp: AxiosResponse) => {
                    if (resp.data) {
                        setOptions((prev) => {
                            const mappedValues: SelectWithSearchOption[] = resp.data.map((obj: unknown) => {
                                const castedObj = obj as ControlledResponse;
                                return {
                                    ...castedObj,
                                    name: castedObj.name,
                                    value: castedObj.id
                                };
                            });

                            if (defaultValues) {
                                return [...mappedValues];
                            }

                            return [...prev, ...mappedValues].filter(
                                (obj, index) =>
                                    index === mappedValues.findIndex((obj2) => obj2.value === obj.value)
                            );
                        });
                    }
                });

                if (onLoaded) {
                    onLoaded();
                }
            })(),
        [mode, dataSource, getEndPoint, queryParams, defaultValues]
    );

    useEffect(() => {
        let canProceed = !prevQueryParams.current;
        if (!canProceed) {
            canProceed = prevQueryParams.current !== JSON.stringify(queryParams);
        }
        const defValues = getDefaultValue();
        if (canProceed) {
            if (onChange && queryParams) {
                const values = defValues.map((val) => val.value as string);
                if (value) {
                    onChange(value, {});
                } else if (clearAllDataOnQuery) {
                    onChange(undefined, {});
                } else if (allowMultipleValues) {
                    onChange(defValues.length !== 0 ? values : undefined, {});
                } else {
                    onChange(defValues.length !== 0 ? values[0] ?? undefined : undefined, {});
                }

                prevQueryParams.current = JSON.stringify(queryParams);
            }
            if (options.length !== 0) {
                setOptions(defValues);
                if (!queryRequired || (queryRequired && queryParams)) {
                    loadData().finally();
                }
            }
        }
    }, [queryParams, prevQueryParams.current]);

    useEffect(() => {
        if (!loading) {
            return undefined;
        }
        if ((queryRequired && queryParams) || !queryRequired) {
            loadData().finally();
        }

        return undefined;
    }, [loading]);

    const proceedExtraFields = useCallback(
        (
            newVal:
                | {
                      name: string;
                      value: string;
                  }
                | {
                      name: string;
                      value: string;
                  }[]
        ) => {
            const extraFieldsResult:
                | {
                      [p: string]: {
                          [p: string]: unknown;
                      };
                  }
                | undefined = {};
            if (extraFields) {
                let foundObject;
                if (Array.isArray(newVal)) {
                    foundObject = options.filter((val) =>
                        newVal.find((selected) => val.id === selected.value)
                    );
                } else {
                    foundObject = options.filter((val) => newVal.value === val.id);
                }
                foundObject.forEach((val) => {
                    const groupedObject: {
                        [p: string]: unknown;
                    } = {};
                    extraFields.forEach((extra) => {
                        if (Object.hasOwn(val, extra)) {
                            groupedObject[extra] = val[extra];
                        }
                    });
                    extraFieldsResult[val.value as string] = groupedObject;
                });
            }

            return extraFieldsResult;
        },
        [extraFields, options]
    );

    const onChanged = useCallback(
        (
            newValue:
                | {
                      name: string;
                      value: string;
                  }
                | {
                      name: string;
                      value: string;
                  }[]
                | null
        ) => {
            if (onChange) {
                if (!newValue) {
                    onChange(undefined, {});
                    return;
                }

                if (allowMultipleValues) {
                    const valArr = newValue as {
                        name: string;
                        value: string;
                    }[];
                    const result = valArr.map((inpt: { name: string; value: string }) => inpt.value);
                    const extraFieldsResult = proceedExtraFields(valArr);
                    onChange(result, extraFieldsResult);
                } else {
                    const val = newValue as {
                        name: string;
                        value: string;
                    };
                    const extraFieldsResult = proceedExtraFields(val);
                    onChange(val.value, extraFieldsResult);
                }
            }
        },
        [onChange, allowMultipleValues, proceedExtraFields]
    );

    const foundValue = useMemo(() => {
        if (value) {
            const foundOption = options.filter((v: SelectWithSearchOption) => {
                if (allowMultipleValues || Array.isArray(value)) {
                    return value.indexOf(v.value as string) >= 0;
                }
                return v.value === value;
            });
            if (foundOption.length > 0) {
                return allowMultipleValues ? foundOption : foundOption[0];
            }
        }
        if (selectAllOption) {
            return DEFAULT_STATE[0];
        }

        if (allowMultipleValues) {
            return [];
        }

        return undefined;
    }, [value, options, allowMultipleValues, selectAllOption]);

    return (
        <SelectWithSearch
            inputProps={inputProps}
            mode={'standard'}
            label={label}
            onChange={onChanged}
            onBlur={onBlur}
            value={foundValue}
            options={options}
            loading={loading}
            open={open}
            required={required}
            errors={errors}
            allowMultipleValues={allowMultipleValues}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            disabled={disabled}
            readonly={readonly}
            filterSelectedOptions={filterSelectedOptions}
            excludeValues={excludeValues}
            placeholder={placeholder}
        />
    );
};

export default SelectFromDict;
