import React, { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react';
import WithSecurityWrapper from './WithSecurityWrapper';
import { WithSecurityProp } from './WithSecurity.types';

const withSecurity = <T,>(
    page: string,
    PageComponent: React.ComponentType<T & WithSecurityProp>
): ForwardRefExoticComponent<PropsWithoutRef<Omit<T, keyof WithSecurityProp>> & RefAttributes<unknown>> =>
    React.forwardRef((pageProps: Omit<T, keyof WithSecurityProp>, ref: React.ForwardedRef<unknown>) => (
        <WithSecurityWrapper
            page={page}
            pageProps={pageProps}
            ref={ref}
            PageComponent={PageComponent as never as React.ComponentType<WithSecurityProp>}
        />
    ));

export default withSecurity;
