import { LoadingStatus } from 'reduxProvider/loadingReducerProvider/loading.types';
import { AppDispatch, RootState } from '../config/configureStore';
import { useAppSelector } from './hook';
import { hideLoading, loggedInMode, showLoading } from '../loadingReducerProvider/loading';

const loadingStatusProvider = (state: RootState) => state.loading;
const selector: () => LoadingStatus = () => useAppSelector(loadingStatusProvider);
const show = (dispatch: AppDispatch) => {
    dispatch(showLoading());
};
const hide = (dispatch: AppDispatch) => {
    dispatch(hideLoading());
};
const changeLoggedInMode = (dispatch: AppDispatch, mode: boolean) => {
    dispatch(loggedInMode(mode));
};

export default { selector, show, hide, changeLoggedInMode };
