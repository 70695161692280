import { PagingCriteria } from 'global/component/Criteria/Criteria.types';
import { Paging } from 'index.types';
import { AxiosType, useRequestService } from 'utils';
import { useCallback, useMemo, useRef } from 'react';
import { AxiosResponse } from 'axios';
import { LoadGridDataService } from './LoadGridDataService.types';

const useLoadDataGridService = <T>(
    basePath: string,
    criteria: PagingCriteria,
    paging: Paging,
    onChangeRowCount: (rowCount: number) => void,
    mappedFunction: (element: T) => T,
    onChangeData: (data: Array<T>) => void,
    onChangeLoading: (loadingStatus: boolean) => void,
    shouldAddPaging?: () => boolean,
    extraChecksForHandle?: () => boolean
): LoadGridDataService => {
    const { get } = useRequestService(false, true, false, { basePath });
    const rowCountRef = useRef<number | undefined>(undefined);

    const getCountData = useCallback(
        (): Promise<void | AxiosResponse> =>
            get(AxiosType.AUTH, '/count', criteria).then((resp: AxiosResponse) => {
                if (typeof resp.data === 'number') {
                    rowCountRef.current = resp.data;
                    onChangeRowCount(resp.data);
                }
            }),
        [get, criteria, onChangeRowCount]
    );
    const getData = useCallback(async (): Promise<void | AxiosResponse> => {
        let query = criteria;
        if ((shouldAddPaging && shouldAddPaging()) || !shouldAddPaging) {
            query = {
                ...criteria,
                ...paging
            };
        }
        return get(AxiosType.AUTH, '', query).then((resp: AxiosResponse) => {
            if (resp.data) {
                onChangeData((resp.data as Array<T>).map((data) => mappedFunction(data)));
            }
        });
    }, [get, criteria, shouldAddPaging, paging, onChangeData, mappedFunction]);
    const handleSearchGeneral = useCallback(
        (isCountRequest: boolean) => (isCountRequest ? getCountData() : getData()),
        [getCountData, getData]
    );
    const handleSearchWrapper = useCallback(
        (withReduce = true, isCountRequest = false) => {
            onChangeLoading(true);

            (withReduce
                ? [handleSearchGeneral(true), handleSearchGeneral(false)].reduce(
                      (chain, task) => chain.then(() => task),
                      Promise.resolve()
                  )
                : handleSearchGeneral(isCountRequest)
            ).finally(() => {
                onChangeLoading(false);
            });
        },
        [onChangeLoading, handleSearchGeneral]
    );

    const onPageChangeHandle = useCallback(() => {
        if (paging.pageSize && rowCountRef.current && (!extraChecksForHandle || extraChecksForHandle())) {
            handleSearchWrapper(false);
        }
    }, [extraChecksForHandle, paging.pageSize, handleSearchWrapper]);
    const onCriteriaChangeHandle = useCallback(() => {
        if (paging.pageSize && (!extraChecksForHandle || extraChecksForHandle())) {
            handleSearchWrapper(true);
        }
    }, [extraChecksForHandle, paging.pageSize, handleSearchWrapper]);

    const handleSearch = useCallback(
        (withReduce: boolean | undefined, isCountRequest: boolean | undefined) =>
            handleSearchWrapper(
                withReduce === undefined ? true : withReduce,
                isCountRequest === undefined ? false : isCountRequest
            ),
        [handleSearchWrapper]
    );

    return useMemo(
        () => ({
            handleSearch,
            onCriteriaChangeHandle,
            onPageChangeHandle
        }),
        [handleSearch, onCriteriaChangeHandle, onPageChangeHandle]
    );
};

export default useLoadDataGridService;
