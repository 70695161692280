import { styled, tooltipClasses, TooltipProps } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';

const ErrorTooltip = styled(({ className, children, ...props }: TooltipProps) => {
    const [classes, setClasses] = useState({ popper: className });

    useEffect(() => {
        setClasses({ popper: className });
    }, [className]);

    return (
        <Tooltip {...props} arrow={true} classes={classes}>
            {children}
        </Tooltip>
    );
})(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#d32f2f'
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#d32f2f'
    }
}));

export default ErrorTooltip;
