import { GlobalPermissionsConstant } from 'global/globalSecurity';

const MEMBER_ID_PARAM = 'memberId';
const MEMBER_ID_REPLACEMENT_PARAM = `__${MEMBER_ID_PARAM}__`;
const MEMBER_GENERAL_URL = 'member';
const MEMBER_URLS = {
    MEMBER_LIST: `list`,
    MEMBER_EDIT: `edit`,
    MEMBER_NEW: `new`
};
const MEMBER_PARAMS = {
    ID_PARAM: MEMBER_ID_PARAM,
    ID_REPLACEMENT_PARAM: MEMBER_ID_REPLACEMENT_PARAM,
    GENERAL_URL: MEMBER_GENERAL_URL
};
const MEMBER_PAGES_URLS = {
    NEW: `${MEMBER_PARAMS.GENERAL_URL}/${MEMBER_URLS.MEMBER_NEW}`,
    LIST: `${MEMBER_PARAMS.GENERAL_URL}/${MEMBER_URLS.MEMBER_LIST}`,
    EDIT: `${MEMBER_PARAMS.GENERAL_URL}/${MEMBER_URLS.MEMBER_EDIT}/${MEMBER_PARAMS.ID_REPLACEMENT_PARAM}`,
    MEMO: MEMBER_PARAMS.GENERAL_URL.toUpperCase()
};
const MEMBER_SECURITY = {
    INFO: 'MemberInfo',
    NEW: 'MemberNew',
    EDIT: 'MemberEdit',
    SEC: {
        ...GlobalPermissionsConstant
    }
};

export { MEMBER_URLS, MEMBER_PARAMS, MEMBER_PAGES_URLS, MEMBER_SECURITY };
