import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Backdrop, CircularProgress, LinearProgress } from '@mui/material';
import { contextActions, langModelActions, loadingActions, useAppDispatch } from 'reduxProvider';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import { Cookies } from 'react-cookie';
import { AxiosType, useRequestService } from 'utils';
import { ACCESS_TOKEN, USER_TYPE } from 'global/globalSecurity';
import { AxiosResponse } from 'axios';
import { UserType } from 'pojo/user';
import { KeyValueType } from 'pojo/common';
import { clearCookies } from 'global/globalFunctions';
import { useSnackbar } from 'notistack';
import MainMenu from '../MainMenu/MainMenu';

const COOKIES = new Cookies();

const App = () => {
    const { status, loggedIn } = loadingActions.selector();
    const { get } = useRequestService(false, false, false, undefined, true);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const [appLoading, setAppLoading] = useState<boolean>(false);

    const contextLoadPromise = useCallback(() => {
        get(AxiosType.AUTH, '/context', {})
            .then((resp: AxiosResponse) => {
                if (resp.data) {
                    const { user, roles } = resp.data as { user: UserType; roles: Array<KeyValueType> };
                    contextActions.applyUserContext(dispatch, user);
                    contextActions.applyRoleEnumContext(dispatch, roles);
                }
            })
            .catch(() => {
                clearCookies();
            });
    }, [get, clearCookies, contextActions, dispatch]);

    const langModelLoadPromise = useCallback(() => {
        get(AxiosType.ANON, '/external/translate', {})
            .then((resp: AxiosResponse) => {
                if (resp.data) {
                    langModelActions.applyLanguageModel(dispatch, { spaModel: resp.data });
                }
            })
            .catch(() => {
                enqueueSnackbar('Error al aplicar el modelo de lenguaje', { variant: 'error' });
            });
    }, [get, dispatch, langModelActions, enqueueSnackbar]);

    useLayoutEffect(() => {
        sessionStorage.clear();
        loadingActions.show(dispatch);
        const accessToken = COOKIES.get(ACCESS_TOKEN);
        const userType = COOKIES.get(USER_TYPE);
        const initialLoading = [langModelLoadPromise()];
        if (accessToken && userType) {
            initialLoading.push(contextLoadPromise());
        }
        Promise.all(initialLoading).finally(() => {
            loadingActions.hide(dispatch);
        });
    }, []);

    useEffect(() => {
        setAppLoading(status > 0);
    }, [status]);

    return (
        <>
            {(loggedIn || !appLoading) && (
                <>
                    <BrowserRouter>
                        <MainMenu />
                    </BrowserRouter>
                    <Backdrop open={appLoading}>
                        <CircularProgress />
                    </Backdrop>
                </>
            )}
            {appLoading && !loggedIn && (
                <>
                    <LinearProgress />
                    <div className={'main-loading'}>
                        <img
                            className={'loading-logo'}
                            alt={'brand-logo'}
                            src={'assets/brand/brand-logo.svg'}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default App;
