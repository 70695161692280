import { object, string } from 'zod';
import UserCUSchema from 'pojo/user/UserCUSchema';

export default UserCUSchema.merge(
    object({
        password: string({
            invalid_type_error: 'Se requiere contraseña',
            required_error: 'Se requiere contraseña'
        }).min(1, 'Se requiere contraseña')
    })
);
