import {
    ERequestType,
    IRequestServiceOutput,
    RequestOptions
} from 'utils/RequestService/RequestService.types';
import useAxiosService from 'utils/AxiosService/AxiosService';
import { AxiosType } from 'utils/AxiosService/AxiosService.types';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { buildQueryParams } from 'global/globalFunctions';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { langModelActions, loadingActions, useAppDispatch } from 'reduxProvider';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

const useRequestService = (
    allowLoading: boolean,
    allowErrorSnackbar: boolean,
    allowSuccessSnackbar: boolean,
    options?: RequestOptions,
    needThrowError?: boolean
): IRequestServiceOutput => {
    const { spaModel } = langModelActions.selector();
    const dispatch = useAppDispatch();
    const { getAxiosInstance } = useAxiosService();

    const showHideLoadingIfNeeded = useCallback(
        (show: boolean) => {
            if (allowLoading) {
                if (show) {
                    loadingActions.show(dispatch);
                } else {
                    loadingActions.hide(dispatch);
                }
            }
        },
        [allowLoading]
    );
    const showSuccessSnackbarIfNeeded = useCallback(
        (msg?: string) => {
            if (allowSuccessSnackbar && msg) {
                enqueueSnackbar(msg, { variant: 'success' });
            }
        },
        [allowSuccessSnackbar]
    );
    const showErrorSnackbarIfNeeded = useCallback(
        (msg: string) => {
            if (allowErrorSnackbar) {
                enqueueSnackbar(msg, { variant: 'error' });
            }
        },
        [allowErrorSnackbar]
    );
    const makeRequestByType = useCallback(
        (
            axios: AxiosInstance,
            type: ERequestType,
            path: string,
            data: object,
            headers?: object
        ): Promise<AxiosResponse<unknown, unknown>> => {
            switch (type) {
                case ERequestType.GET: {
                    let query = buildQueryParams(data);
                    if (path.indexOf('?') >= 0) {
                        query = `&${query.substring(1)}`;
                    }
                    return axios.get(`${path}${query}`, headers ? { headers } : undefined);
                }
                case ERequestType.POST:
                    return axios.post(path, data, headers ? { headers } : undefined);
                default:
                    return new Promise<AxiosResponse>((_resolve, reject) => {
                        reject(new AxiosError(spaModel[LangAliasEnum.FAILED_TO_CONNECT_TO_SERVER_MSG]));
                    });
            }
        },
        [spaModel]
    );
    const request = useCallback(
        async (
            axiosType: AxiosType,
            type: ERequestType,
            path: string,
            data: object,
            successMsg?: string,
            headers?: object
        ) => {
            showHideLoadingIfNeeded(true);
            const axios: AxiosInstance = getAxiosInstance(axiosType);
            return makeRequestByType(axios, type, path, data, headers)
                .then((resp: AxiosResponse) => {
                    if (type === ERequestType.POST && resp.data) {
                        showSuccessSnackbarIfNeeded(successMsg);
                    }
                    return resp;
                })
                .catch((err: AxiosError) => {
                    showErrorSnackbarIfNeeded(err.message);
                    if (needThrowError) {
                        throw AxiosError.from(err);
                    } else {
                        return {} as AxiosResponse;
                    }
                })
                .finally(() => {
                    showHideLoadingIfNeeded(false);
                });
        },
        [
            showHideLoadingIfNeeded,
            getAxiosInstance,
            makeRequestByType,
            showSuccessSnackbarIfNeeded,
            showErrorSnackbarIfNeeded,
            needThrowError
        ]
    );

    const get = useCallback(
        (axiosType: AxiosType, path: string, data: object, successMsg?: string) =>
            request(
                axiosType,
                ERequestType.GET,
                options?.basePath ? `${options.basePath}${path}` : path,
                data,
                successMsg,
                undefined
            ),
        [request, options?.basePath]
    );
    const post = useCallback(
        (axiosType: AxiosType, path: string, data: object, successMsg?: string, headers?: object) =>
            request(
                axiosType,
                ERequestType.POST,
                options?.basePath ? `${options.basePath}${path}` : path,
                data,
                successMsg,
                headers
            ),
        [request, options?.basePath]
    );

    return useMemo(
        () => ({
            get,
            post
        }),
        [get, post]
    );
};

export default useRequestService;
