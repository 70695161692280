import React, { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, useMemo } from 'react';
import WithCommonPageWrapper from 'global/abstraction/WithCommonPage/WithCommonPageWrapper';
import { Bundle, BundleUrls } from 'index.types';
import { ADMIN_PAGES_URL } from 'admin/index.url';
import { CLIENT_PAGES_URL } from 'client/index.url';
import { COMMON_PAGES_URL } from 'common/index.url';
import { WithCommonPageProps } from './WithCommonPage.types';

const withCommonPage = <T, R extends BundleUrls = BundleUrls>(
    bundle: Bundle,
    PageComponent: React.ComponentType<T & WithCommonPageProps<R>>
): ForwardRefExoticComponent<
    PropsWithoutRef<Omit<T, keyof WithCommonPageProps<R>>> & RefAttributes<unknown>
> =>
    React.forwardRef((pageProps: Omit<T, keyof WithCommonPageProps<R>>, ref: React.ForwardedRef<unknown>) => {
        const bundleUrls = useMemo((): R => {
            switch (bundle) {
                case Bundle.ADMIN:
                    return ADMIN_PAGES_URL as never as R;
                case Bundle.CLIENT:
                    return CLIENT_PAGES_URL as never as R;
                default:
                    return COMMON_PAGES_URL as never as R;
            }
        }, [bundle]);

        return (
            <WithCommonPageWrapper
                bundle={bundle}
                bundleUrls={bundleUrls}
                pageProps={pageProps}
                ref={ref}
                PageComponent={PageComponent as never as React.ComponentType<WithCommonPageProps<BundleUrls>>}
            />
        );
    });

export default withCommonPage;
