import { IndexableObject } from 'index.types';

export type LangModel = {
    spaModel: IndexableObject<string>;
};

export enum LangAliasEnum {
    UNUSED = '',
    LOG_IN_INFO_MSG = 'LOG_IN_INFO_MSG',
    FORGET_PASSWORD_INFO_MSG = 'FORGET_PASSWORD_INFO_MSG',
    ADDRESS_INFO_INFO_MSG = 'ADDRESS_INFO_INFO_MSG',
    GENERAL_INFO_INFO_MSG = 'GENERAL_INFO_INFO_MSG',
    CONTACT_INFO_INFO_MSG = 'CONTACT_INFO_INFO_MSG',
    VISIT_INFO_INFO_MSG = 'VISIT_INFO_INFO_MSG',
    REQ_FIELDS_INFO_MSG = 'REQ_FIELDS_INFO_MSG',
    MARKETING_INF_INF_MSG = 'MARKETING_INF_INF_MSG',
    TOGGLE_PWD_VISIBILITY = 'TOGGLE_PWD_VISIBILITY',
    NO_PERMISSION_MSG = 'NO_PERMISSION_MSG',
    DELETION_SUCCEEDED_MSG = 'DELETION_SUCCEEDED_MSG',
    FAILED_TO_CONNECT_TO_SERVER_MSG = 'FAILED_TO_CONNECT_TO_SERVER_MSG',

    MY_PROFILE_TITLE = 'MY_PROFILE_TITLE',
    LEADS_TITLE = 'LEADS_TITLE',
    NEW_LEAD_TITLE = 'NEW_LEAD_TITLE',
    EDIT_LEAD_TITLE = 'EDIT_LEAD_TITLE',
    STATISTIC_TITLE = 'STATISTIC_TITLE',
    MEMBERS_TITLE = 'MEMBERS_TITLE',
    NEW_MEMBER_TITLE = 'NEW_MEMBER_TITLE',
    EDIT_MEMBER_TITLE = 'EDIT_MEMBER_TITLE',
    REFUND_REQUEST_TITLE = 'REFUND_REQUEST_TITLE',

    ACTION_LEAD_COLUMN_TITLE = 'ACTION_LEAD_COLUMN_TITLE',
    LEAD_INFO_COLUMN_TITLE = 'LEAD_INFO_COLUMN_TITLE',
    CLIENT_INFO_COLUMN_TITLE = 'CLIENT_INFO_COLUMN_TITLE',
    REFUND_REASON_COLUMN_TITLE = 'REFUND_REASON_COLUMN_TITLE',
    DATE_ASSIGNED_COLUMN_TITLE = 'DATE_ASSIGNED_COLUMN_TITLE',
    CONTRACT_COLUMN_TITLE = 'CONTRACT_COLUMN_TITLE',
    DELIVERED_COLUMN_TITLE = 'DELIVERED_COLUMN_TITLE',
    BILLABLE_COLUMN_TITLE = 'BILLABLE_COLUMN_TITLE',
    AVG_PRICE_COLUMN_TITLE = 'AVG_PRICE_COLUMN_TITLE',
    SPENT_COLUMN_TITLE = 'SPENT_COLUMN_TITLE',
    RETURN_RATE_COLUMN_TITLE = 'RETURN_RATE_COLUMN_TITLE',
    RETURNED_COLUMN_TITLE = 'RETURNED_COLUMN_TITLE',
    MEMBER_INFO_COLUMN_TITLE = 'MEMBER_INFO_COLUMN_TITLE',

    CREATE_LEAD_DATE_TIME_LABEL = 'CREATE_LEAD_DATE_TIME_LABEL',
    NAME_LABEL = 'NAME_LABEL',
    FROM_LABEL = 'FROM_LABEL',
    TO_LABEL = 'TO_LABEL',
    LEAD_FULL_NAME_LABEL = 'LEAD_FULL_NAME_LABEL',
    COMPANY_NAME_LABEL = 'COMPANY_NAME_LABEL',
    LEAD_SOURCE_LABEL = 'LEAD_SOURCE_LABEL',
    EMAIL_LABEL = 'EMAIL_LABEL',
    ADDRESS_LABEL = 'ADDRESS_LABEL',
    PLATFORM_LABEL = 'PLATFORM_LABEL',
    LANDING_PAGE_LABEL = 'LANDING_PAGE_LABEL',
    ROLE_LABEL = 'ROLE_LABEL',
    REASON_LABEL = 'REASON_LABEL',
    NOTE_LABEL = 'NOTE_LABEL',
    CREATION_DATE_LABEL = 'CREATION_DATE_LABEL',
    STATUS_LABEL = 'STATUS_LABEL',
    PRICE_LABEL = 'PRICE_LABEL',
    DESCRIPTION_LABEL = 'DESCRIPTION_LABEL',
    PRODUCT_LABEL = 'PRODUCT_LABEL',
    STATE_REGION_LABEL = 'STATE_REGION_LABEL',
    CITY_LOCALITY_LABEL = 'CITY_LOCALITY_LABEL',
    PASSWORD_LABEL = 'PASSWORD_LABEL',
    FIRST_NAME_LABEL = 'FIRST_NAME_LABEL',
    LAST_NAME_LABEL = 'LAST_NAME_LABEL',
    PHONE_LABEL = 'PHONE_LABEL',
    STREET_ADDRESS_LABEL = 'STREET_ADDRESS_LABEL',
    STREET_ADDRESS_TWO_LABEL = 'STREET_ADDRESS_TWO_LABEL',
    POSTAL_CODE_LABEL = 'POSTAL_CODE_LABEL',
    CAMPAIGN_LABEL = 'CAMPAIGN_LABEL',
    KEY_WORD_LABEL = 'KEY_WORD_LABEL',
    ADD_MARKETING_LABEL = 'ADD_MARKETING_LABEL',
    GCLID_LABEL = 'GCLID_LABEL',
    CURRENT_PASSWORD_LABEL = 'CURRENT_PASSWORD_LABEL',
    NEW_PASSWORD_LABEL = 'NEW_PASSWORD_LABEL',
    CONFIRM_PASSWORD_LABEL = 'CONFIRM_PASSWORD_LABEL',
    INDUSTRY_LABEL = 'INDUSTRY_LABEL',
    SUBSCRIBES_PRODUCTS_LABEL = 'SUBSCRIBES_PRODUCTS_LABEL',
    AVAILABLE_FUNDS_LABEL = 'AVAILABLE_FUNDS_LABEL',
    COUNT_LEADS_PER_DAY_LABEL = 'COUNT_LEADS_PER_DAY_LABEL',
    PRICE_PER_LEAD_LABEL = 'PRICE_PER_LEAD_LABEL',
    IS_EXCLUSIVE_LABEL = 'IS_EXCLUSIVE_LABEL',
    DELIVERED_TODAY_LABEL = 'DELIVERED_TODAY_LABEL',
    DELIVERED_DAY_LIMIT_LABEL = 'DELIVERED_DAY_LIMIT_LABEL',
    SPEND_TODAY_LABEL = 'SPEND_TODAY_LABEL',
    CREDIT_REMAINING_LABEL = 'CREDIT_REMAINING_LABEL',
    MONTH_RETURNS_RATE_LABEL = 'MONTH_RETURNS_RATE_LABEL',
    PENDING_LABEL = 'PENDING_LABEL',

    SEARCH_BY_NAME_PLACEHOLDER = 'SEARCH_BY_NAME_PLACEHOLDER',
    SEARCH_LEAD_PLACEHOLDER = 'SEARCH_LEAD_PLACEHOLDER',
    COUNT_LEADS_PER_DAY_PLACEHOLDER = 'COUNT_LEADS_PER_DAY_PLACEHOLDER',
    GCLID_PLACEHOLDER = 'GCLID_PLACEHOLDER',
    ADD_MARKETING_PLACEHOLDER = 'ADD_MARKETING_PLACEHOLDER',
    KEY_WORD_PLACEHOLDER = 'KEY_WORD_PLACEHOLDER',
    CAMPAIGN_PLACEHOLDER = 'CAMPAIGN_PLACEHOLDER',
    PLATFORM_PLACEHOLDER = 'PLATFORM_PLACEHOLDER',
    LANDING_PAGE_PLACEHOLDER = 'LANDING_PAGE_PLACEHOLDER',
    LEAD_SOURCE_PLACEHOLDER = 'LEAD_SOURCE_PLACEHOLDER',
    EMAIL_PLACEHOLDER = 'EMAIL_PLACEHOLDER',
    STATE_REGION_PLACEHOLDER = 'STATE_REGION_PLACEHOLDER',
    CITY_LOCALITY_PLACEHOLDER = 'CITY_LOCALITY_PLACEHOLDER',
    PHONE_PLACEHOLDER = 'PHONE_PLACEHOLDER',
    REFUND_NOTE_PLACEHOLDER = 'REFUND_NOTE_PLACEHOLDER',
    DESCRIPTION_PLACEHOLDER = 'DESCRIPTION_PLACEHOLDER',
    FIRST_NAME_PLACEHOLDER = 'FIRST_NAME_PLACEHOLDER',
    LAST_NAME_PLACEHOLDER = 'LAST_NAME_PLACEHOLDER',
    STREET_ADDRESS_PLACEHOLDER = 'STREET_ADDRESS_PLACEHOLDER',
    STREET_ADDRESS_TWO_PLACEHOLDER = 'STREET_ADDRESS_TWO_PLACEHOLDER',
    POSTAL_CODE_PLACEHOLDER = 'POSTAL_CODE_PLACEHOLDER',

    CONTACTS_TAB_TITLE = 'CONTACTS_TAB_TITLE',
    PERSONAL_DETAILS_TAB_TITLE = 'PERSONAL_DETAILS_TAB_TITLE',
    CHANGE_PASSWORD_TAB_TITLE = 'CHANGE_PASSWORD_TAB_TITLE',
    CLIENT_SETTINGS_TAB_TITLE = 'CLIENT_SETTINGS_TAB_TITLE',
    ACCOUNT_HISTORY_TAB_TITLE = 'ACCOUNT_HISTORY_TAB_TITLE',
    INFO_TAB_TITLE = 'INFO_TAB_TITLE',

    REFUND_NOTE_ACCORDION_TITLE = 'REFUND_NOTE_ACCORDION_TITLE',

    LIST_VIEW_MODE_BUTTON = 'LIST_VIEW_MODE_BUTTON',
    ASSIGNED_VIEW_MODE_BUTTON = 'ASSIGNED_VIEW_MODE_BUTTON',

    FORGET_PASSWORD_BUTTON = 'FORGET_PASSWORD_BUTTON',
    LOOKUP_BUTTON = 'LOOKUP_BUTTON',
    DELETE_BUTTON = 'DELETE_BUTTON',
    EDIT_BUTTON = 'EDIT_BUTTON',
    REFUND_BUTTON = 'REFUND_BUTTON',
    ACCEPT_BUTTON = 'ACCEPT_BUTTON',
    DECLINE_BUTTON = 'DECLINE_BUTTON',
    NOTHING_BUTTON = 'NOTHING_BUTTON',
    LOGIN_BUTTON = 'LOGIN_BUTTON',
    RESET_BUTTON = 'RESET_BUTTON',
    CLOSE_BUTTON = 'CLOSE_BUTTON',
    CREATE_BUTTON = 'CREATE_BUTTON',
    REQUEST_BUTTON = 'REQUEST_BUTTON',
    CALL_BUTTON = 'CALL_BUTTON',
    SAVE_BUTTON = 'SAVE_BUTTON',
    CHANGE_STATUS_BUTTON = 'CHANGE_STATUS_BUTTON',
    EXPORT_BUTTON = 'EXPORT_BUTTON',
    CANCEL_BUTTON = 'CANCEL_BUTTON',
    UPDATE_BUTTON = 'UPDATE_BUTTON',
    CLOSE_PROJECT_BUTTON = 'CLOSE_PROJECT_BUTTON',
    RESET_PASSWORD_BUTTON = 'RESET_PASSWORD_BUTTON',
    MY_ACCOUNT_BUTTON = 'MY_ACCOUNT_BUTTON',
    LOGOUT_BUTTON = 'LOGOUT_BUTTON',
    BACK_BUTTON = 'BACK_BUTTON',
    REFRESH_DATA_BUTTON = 'REFRESH_DATA_BUTTON',
    CHANGE_PASSWORD_BUTTON = 'CHANGE_PASSWORD_BUTTON',
    UPLOAD_BUTTON_AVATAR = 'UPLOAD_BUTTON_AVATAR',

    USER_STATISTIC = 'USER_STATISTIC'
}
