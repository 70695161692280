import { LayoutProps } from 'common/Layout/Layout.types';
import { Bundle } from 'index.types';
import { AdminAuthGroupPages, InMenuPages } from './index.routing';

const GroupPages = [AdminAuthGroupPages];
const LayoutConfig: LayoutProps = {
    bundle: Bundle.ADMIN,
    inMenuPages: InMenuPages,
    mainBundleGroup: GroupPages
};

export { GroupPages, LayoutConfig, AdminAuthGroupPages };
