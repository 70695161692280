import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TabGroupElement, TabGroupsProps, TabPanelProps } from 'global/component/TabGroups/TabGroups.types';
import { Box, Tab, Tabs } from '@mui/material';
import { CONTAINER_SX } from './TabGroups.styles';

const TabPanel = ({ children, value, index, tabBaseName }: TabPanelProps) => (
    <div
        role={'tabpanel'}
        hidden={value !== index}
        id={`${tabBaseName}-tabpanel-${index}`}
        aria-labelledby={`${tabBaseName}-tab-${index}`}
        className={`${tabBaseName}-tab-container`}
    >
        {value === index && <div className={`${tabBaseName}-tab`}>{children}</div>}
    </div>
);

const TabGroups = <T,>({
    tabs,
    componentData,
    tabBaseName,
    tabDefault,
    orientation,
    boxClassName,
    secData
}: TabGroupsProps<T>) => {
    const [selectedTab, setSelectedTab] = useState<number>(tabDefault ?? 0);

    useEffect(() => {
        setSelectedTab(tabDefault ?? 0);
    }, [tabDefault]);

    const onChangeSelectedTab = useCallback((_: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    }, []);

    const allowedTabs = useMemo(
        () =>
            tabs.filter(({ permission }) => {
                if (permission && secData) {
                    return secData[permission];
                }
                return true;
            }),
        [secData, tabs]
    );

    return (
        <Box className={boxClassName} sx={CONTAINER_SX}>
            <Tabs
                orientation={orientation ?? 'horizontal'}
                variant={'standard'}
                value={selectedTab}
                onChange={onChangeSelectedTab}
                scrollButtons={true}
            >
                {allowedTabs.map(({ label, key, index, Icon }: TabGroupElement) => (
                    <Tab
                        key={key}
                        label={label}
                        id={`${tabBaseName}-tab-${index}`}
                        aria-controls={`${tabBaseName}-tabpanel-${index}`}
                        icon={Icon}
                        iconPosition={'start'}
                    />
                ))}
            </Tabs>
            {allowedTabs.map(({ index, Component, key }: TabGroupElement) => (
                <TabPanel key={key} index={index} value={selectedTab} tabBaseName={tabBaseName}>
                    <Component {...componentData} />
                </TabPanel>
            ))}
        </Box>
    );
};

export default TabGroups;
