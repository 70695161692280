import { AutocompleteRenderInputParams, CircularProgress, InputAdornment, InputProps } from '@mui/material';
import { FieldError } from 'react-hook-form';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { ErrorAdornment, InfoAdornment } from 'global/component';
import { FilledInputProps } from '@mui/material/FilledInput';

function getComponentEndAdornment(
    params: AutocompleteRenderInputParams,
    readonly?: boolean,
    errors?: FieldError,
    inputProps?: Partial<InputProps>,
    loading?: boolean,
    infoMsg?: string
) {
    return {
        readOnly: !!readonly,
        endAdornment: (
            <>
                {inputProps && inputProps.endAdornment}
                {params.InputProps.endAdornment}
                {(!inputProps || !inputProps.endAdornment) && (
                    <>
                        <ErrorAdornment fieldError={errors} />
                        {!!infoMsg && <InfoAdornment msg={infoMsg} />}
                    </>
                )}
                {loading ? <CircularProgress color={'error'} size={20} /> : null}
            </>
        )
    };
}

const getErrorIcon = (fieldError?: FieldError) => ({
    endAdornment: <ErrorAdornment fieldError={fieldError} />
});

const getFieldInputProps = (
    fieldError?: FieldError,
    readOnly?: boolean,
    StartAdornmentIcon?: React.ElementType
) => {
    let defaultInputProps: Partial<FilledInputProps> = {
        endAdornment: <ErrorAdornment fieldError={fieldError} />,
        readOnly: !!readOnly
    };
    if (StartAdornmentIcon) {
        defaultInputProps = {
            ...defaultInputProps,
            startAdornment: (
                <InputAdornment position={'start'}>
                    <StartAdornmentIcon />
                </InputAdornment>
            )
        };
    }
    return defaultInputProps;
};

const getInputPropsForSelect = (
    params: AutocompleteRenderInputParams,
    readonly?: boolean,
    errors?: FieldError,
    inputProps?: Partial<InputProps>,
    loading?: boolean,
    infoMsg?: string
) => ({
    ...params.InputProps,
    ...getComponentEndAdornment(params, readonly, errors, inputProps, loading, infoMsg)
});

const getSearchIconName = () => ({
    startAdornment: (
        <InputAdornment position={'start'}>
            <SearchIcon />
        </InputAdornment>
    )
});

export { getInputPropsForSelect, getFieldInputProps, getErrorIcon, getSearchIconName };
