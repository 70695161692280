import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KeyValueType } from 'pojo/common';
import { TeamType } from 'pojo/team';
import { UserType } from 'pojo/user';
import { Context } from './context.types';

const initialUserState = {
    // profiles: [] as Array<ProfileType>,
    teams: [] as Array<TeamType>
} as UserType;
const initialRolesState = [{}] as Array<KeyValueType>;

const initialState: Context = {
    user: initialUserState,
    roles: initialRolesState
} as Context;

export const contextSlice = createSlice({
    name: 'context',
    initialState,
    reducers: {
        applyUserToContext: (state, action: PayloadAction<UserType>) => {
            state.user = {
                ...state.user,
                ...action.payload
            };
        },
        applyRoleEnumToContext: (state, action: PayloadAction<Array<KeyValueType>>) => {
            state.roles = [...state.roles, ...action.payload];
        },
        logoutUser: (state) => {
            state.user = initialUserState;
        }
    }
});

export const { applyUserToContext, applyRoleEnumToContext, logoutUser } = contextSlice.actions;

export default contextSlice.reducer;
