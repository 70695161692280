import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './AccordionGroups.scss';
import {
    AccordionElement,
    AccordionGroupsProps
} from 'global/component/AccordionGroups/AccordionGroups.types';

const AccordionGroups = <T,>({ accordions, componentData }: AccordionGroupsProps<T>) => (
    <div className={'accordion-padding-bottom'}>
        {accordions.map(({ Component, title, key }: AccordionElement) => (
            <Accordion key={key} defaultExpanded={true} className={'accordion'}>
                <AccordionSummary className={'accordion-summary'} expandIcon={<ExpandMoreIcon />}>
                    <Typography fontSize={33}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={'information'}>
                        <Component {...componentData} />
                    </div>
                </AccordionDetails>
            </Accordion>
        ))}
    </div>
);

export default AccordionGroups;
