import { array, object, string } from 'zod';
import { UserCompactSchema } from 'pojo/user';
import TeamBodySchema from './TeamBodySchema';
import UserInTeamSchema from './UserInTeamSchema';

// TODO fix bug with refine lead on member page
export default object({
    id: string(),
    createdAt: string(),
    lead: UserCompactSchema.refine((data) => !!data?.id, 'Se requiere plomo'),
    usersInTeam: array(UserInTeamSchema).nonempty('Se requiere al menos un miembro del equipo')
}).merge(TeamBodySchema);
