import React from 'react';
import { Box, LinearProgress, LinearProgressProps } from '@mui/material';

const ROOT_BOX = { display: 'flex', alignItems: 'center' };
const PROGRESS_BOX = { width: '100%', mr: 1 };

const LinearProgressWithLabel = ({ value, ...other }: LinearProgressProps & { value: number }) => (
    <Box sx={ROOT_BOX} paddingTop={2} paddingBottom={2}>
        <Box sx={PROGRESS_BOX}>
            <LinearProgress variant={'determinate'} color={'inherit'} value={value} {...other} />
        </Box>
    </Box>
);

export default LinearProgressWithLabel;
