import { COMMON_PAGES_URL } from 'common/index.url';
import { Bundle, BundleUrls } from 'index.types';
import { STATISTIC_URLS } from 'client/Statistic/index.url';

export type ClientBundleUrls = BundleUrls & {
    STAT: {
        MY: string;
    };
};

const CLIENT_PARAM = Bundle.CLIENT;
const CLIENT_PAGES_URL: ClientBundleUrls = {
    LEAD: {
        NEW: `/${CLIENT_PARAM}/${COMMON_PAGES_URL.LEAD.NEW}`,
        LIST: `/${CLIENT_PARAM}/${COMMON_PAGES_URL.LEAD.LIST}`,
        EDIT: `/${CLIENT_PARAM}/${COMMON_PAGES_URL.LEAD.EDIT}`,
        MEMO: COMMON_PAGES_URL.LEAD.MEMO
    },
    PROFILE: {
        MY: `${CLIENT_PARAM}/${COMMON_PAGES_URL.PROFILE.MY}`
    },
    STAT: {
        MY: `${CLIENT_PARAM}/${STATISTIC_URLS.MY}`
    }
};

export { CLIENT_PAGES_URL };
