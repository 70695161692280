import { lazyWithRetry } from 'utils';
import { PageUrl } from 'index.types';
import { guidGenerator } from 'global/globalFunctions';
import { withSecurity } from 'global/abstraction';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';
import { MYPROFILE_SECURITY, MYPROFILE_URLS } from './index.url';

const MyProfile = lazyWithRetry(() => import('./MyProfile'));

const MyProfileUrl: PageUrl = {
    id: guidGenerator(),
    name: 'My Profile',
    aliasName: LangAliasEnum.MY_PROFILE_TITLE,
    redirectUrl: MYPROFILE_URLS.MY,
    routingUrl: MYPROFILE_URLS.MY,
    role: 'all',
    Component: withSecurity(MYPROFILE_SECURITY.MY, MyProfile)
};

export { MyProfileUrl };
