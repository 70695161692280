import { UserCompactType, UserType } from 'pojo/user';
import { RoleType } from 'pojo/role';
import { ProfileType } from 'pojo/profile';

const getFullName = (user: UserType): string => `${user.person.givenName} ${user.person.familyName}`;
const getFullNameByCompactType = (user: UserCompactType): string =>
    `${user.person.givenName} ${user.person.familyName}`;

const getActiveProfile = (user: UserType): ProfileType =>
    // const activeProfile = user.profiles.find((profile) => profile.isActive);
    // return activeProfile!;
    user.profile;

const getDefaultRole = (user: UserType): RoleType => getActiveProfile(user)!.role;

export default { getDefaultRole, getFullName, getFullNameByCompactType, getActiveProfile };
