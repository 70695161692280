import { GlobalPermissionsConstant } from 'global/globalSecurity';

const LEAD_ID_PARAM = 'leadId';
const LEAD_ID_REPLACEMENT_PARAM = `__${LEAD_ID_PARAM}__`;
const LEAD_GENERAL_URL = 'lead';
const LEAD_URLS = {
    LEAD_LIST: `list`,
    LEAD_EDIT: `edit`,
    LEAD_NEW: `new`
};
const LEAD_PARAMS = {
    ID_PARAM: LEAD_ID_PARAM,
    ID_REPLACEMENT_PARAM: LEAD_ID_REPLACEMENT_PARAM,
    GENERAL_URL: LEAD_GENERAL_URL
};
const LEAD_SECURITY = {
    INFO: 'LeadInfo',
    NEW: 'LeadNew',
    EDIT: 'LeadEdit',
    SEC: {
        ...GlobalPermissionsConstant
    }
};

export { LEAD_URLS, LEAD_PARAMS, LEAD_SECURITY };
