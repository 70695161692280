import React from 'react';
import { BundleUrls } from 'index.types';
import { WithCommonPageWrapperProps } from './WithCommonPageWrapper.types';

const WithCommonPageWrapper = React.forwardRef(
    <R, T, Route extends BundleUrls>(
        { pageProps, PageComponent, bundle, bundleUrls }: WithCommonPageWrapperProps<T, Route>,
        ref: React.ForwardedRef<R>
    ) => <PageComponent ref={ref} {...pageProps} bundle={bundle} bundleUrls={bundleUrls} />
);

export default WithCommonPageWrapper;
