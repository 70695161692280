import { GlobalPermissionsConstant } from 'global/globalSecurity';

const MYPROFILE_URLS = {
    MY: `myProfile`
};
const MYPROFILE_SECURITY = {
    MY: 'MyProfile',
    SEC: {
        ...GlobalPermissionsConstant,
        CHANGE_PWD: 'change_pwd'
    }
};

export { MYPROFILE_URLS, MYPROFILE_SECURITY };
