import { AxiosResponse } from 'axios';
import { AxiosType } from 'utils/AxiosService/AxiosService.types';

export enum ERequestType {
    POST = '_post',
    GET = '_get'
}

export type RequestOptions = {
    basePath?: string;
};

export interface IRequestServiceOutput {
    readonly get: (
        axiosType: AxiosType,
        path: string,
        data: object,
        successMsg?: string
    ) => Promise<AxiosResponse<any, any>>;
    readonly post: (
        axiosType: AxiosType,
        path: string,
        data: object,
        successMsg?: string,
        headers?: object
    ) => Promise<AxiosResponse<any, any>>;
}
