import { customDeepPartial } from 'global/globalFunctions';
import { object } from 'zod';
import UserSchema from './UserSchema';
import UserCompactBodySchema from './UserCompactBodySchema';

export default object({})
    .extend(
        customDeepPartial(UserSchema.shape, [
            'phoneNumber',
            'person.locality.region.id',
            'person.locality.id',
            'role',
            'person',
            'profile',
            'profile.role.id',
            'companyName'
        ])
    )
    .merge(UserCompactBodySchema);
