import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import { lazily } from 'react-lazily';
import { guidGenerator } from 'global/globalFunctions';
import { PageUrl } from 'index.types';
import { COMMON_PAGES_URL } from 'common/index.url';
import { withSecurity } from 'global/abstraction';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';
import { LEAD_PARAMS, LEAD_SECURITY, LEAD_URLS } from './index.url';

const { LeadInfo, LeadNew, LeadEdit } = lazily(() => import('./index'));

const LEAD_GUID = guidGenerator();

const LeadUrl: PageUrl = {
    id: LEAD_GUID,
    name: 'Leads',
    label: 'Leads',
    aliasName: LangAliasEnum.LEADS_TITLE,
    redirectUrl: COMMON_PAGES_URL.LEAD.LIST,
    routingUrl: LEAD_URLS.LEAD_LIST,
    inMenu: true,
    role: 'all',
    Component: withSecurity(LEAD_SECURITY.INFO, LeadInfo),
    UrlIcon: ReduceCapacityIcon,
    getGuidBold: () => LEAD_GUID
};
const LeadEditUrl: PageUrl = {
    id: guidGenerator(),
    name: 'Edit lead',
    aliasName: LangAliasEnum.EDIT_LEAD_TITLE,
    role: 'all',
    redirectUrl: COMMON_PAGES_URL.LEAD.EDIT,
    routingUrl: `${LEAD_URLS.LEAD_EDIT}/:${LEAD_PARAMS.ID_PARAM}`,
    replacements: [
        {
            replacement: LEAD_PARAMS.ID_REPLACEMENT_PARAM,
            value: LEAD_PARAMS.ID_PARAM
        }
    ],
    Component: withSecurity(LEAD_SECURITY.EDIT, LeadEdit),
    getGuidBold: () => LeadUrl.id
};
const LeadNewUrl: PageUrl = {
    id: guidGenerator(),
    name: 'New Lead',
    aliasName: LangAliasEnum.NEW_LEAD_TITLE,
    role: 'all',
    redirectUrl: COMMON_PAGES_URL.LEAD.NEW,
    routingUrl: LEAD_URLS.LEAD_NEW,
    Component: withSecurity(LEAD_SECURITY.NEW, LeadNew),
    getGuidBold: () => LeadUrl.id
};
const LeadPages: PageUrl = {
    id: guidGenerator(),
    name: 'Leads',
    aliasName: LangAliasEnum.LEADS_TITLE,
    role: 'all',
    redirectUrl: `${LEAD_PARAMS.GENERAL_URL}`,
    routingUrl: `${LEAD_PARAMS.GENERAL_URL}`,
    inMenu: true,
    children: [LeadEditUrl, LeadNewUrl, LeadUrl]
};

export { LeadPages, LeadUrl, LeadEditUrl, LeadNewUrl };
