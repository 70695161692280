import React, { useCallback, useMemo } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import LEAD_GRID_ADMIN_COLUMNS from 'utils/GridColumnsService/columns/LeadGridColumns/LeadGridAdminColumns';
import LEAD_GRID_CLIENT_COLUMNS from 'utils/GridColumnsService/columns/LeadGridColumns/LeadGridClientColumns';
import MEMBERS_GRID_COLUMNS from 'utils/GridColumnsService/columns/MemberGridColumns/MembersAdminColumns';
import MEMBERS_HISTORY_GRID_COLUMNS from 'utils/GridColumnsService/columns/MemberGridColumns/MemberHistoryGridColumns';
import { DataGridColumn } from 'global/component/CustomDataGrid/CustomDataGrid.types';
import { Stack } from '@mui/material';
import CrudIconButton from 'utils/GridColumnsService/buttons/CrudIconButton';
import ComplexButton from 'utils/GridColumnsService/buttons/ComplexButton';
import LEAD_GRID_APPROVE_COLUMNS from 'utils/GridColumnsService/columns/LeadGridColumns/LeadApproveColumns';
import { ClientProjectStatusEnum } from 'index.types';
import { ProjectType } from 'pojo/project';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';
import { isAcceptDeclinePageVisible, isRefundColumnVisible } from 'common/Lead/leadUtils';
import { langModelActions } from 'reduxProvider';
import {
    DataGridAction,
    DataGridActionHandlers,
    DataGridActionProps,
    GridColumnsService,
    GridsEnum
} from './GridColumnsService.types';

const useGridColumnService = (
    page: GridsEnum,
    columnHeader?: string,
    buttons?: Array<DataGridAction>,
    buttonActionHandlers?: DataGridActionHandlers,
    columnWidth?: number
): GridColumnsService => {
    const { spaModel } = langModelActions.selector();

    const getLookUpButton = useCallback(
        (params: GridRenderCellParams): DataGridActionProps => ({
            label: spaModel[LangAliasEnum.LOOKUP_BUTTON],
            onClick: () => {
                if (buttonActionHandlers?.onLookUp) {
                    buttonActionHandlers.onLookUp(params.row.id as string);
                } else {
                    window.console.group('LookUp entity with ID');
                    window.console.log(params.id);
                    window.console.groupEnd();
                }
            },
            Icon: SearchIcon
        }),
        [buttonActionHandlers?.onLookUp, spaModel]
    );
    const getDeleteButton = useCallback(
        (params: GridRenderCellParams): DataGridActionProps => ({
            label: spaModel[LangAliasEnum.DELETE_BUTTON],
            onClick: () => {
                if (buttonActionHandlers?.onDelete) {
                    buttonActionHandlers.onDelete(params.row.id as string);
                } else {
                    window.console.group('Delete entity with ID');
                    window.console.log(params.id);
                    window.console.groupEnd();
                }
            },
            Icon: DeleteIcon
        }),
        [buttonActionHandlers?.onDelete, spaModel]
    );
    const getEditButton = useCallback(
        (params: GridRenderCellParams): DataGridActionProps => ({
            label: spaModel[LangAliasEnum.EDIT_BUTTON],
            onClick: () => {
                if (buttonActionHandlers?.onEdit) {
                    buttonActionHandlers.onEdit(params.row.id as string);
                } else {
                    window.console.group('Edit entity with ID');
                    window.console.log(params.id);
                    window.console.groupEnd();
                }
            },
            Icon: EditIcon
        }),
        [buttonActionHandlers?.onEdit, spaModel]
    );
    const getRefundButton = useCallback(
        (params: GridRenderCellParams): DataGridActionProps => {
            const project = params.row as ProjectType;
            const { clientInfo } = project!;
            const label = spaModel[LangAliasEnum.REFUND_BUTTON];
            const disabled = clientInfo.status === ClientProjectStatusEnum.RETURN_PENDING;
            const visible = isRefundColumnVisible(clientInfo);
            return {
                label,
                disabled,
                visible,
                onClick: () => {
                    if (buttonActionHandlers?.onRefund) {
                        buttonActionHandlers.onRefund(params.row.id as string);
                    } else {
                        window.console.group('Refund entity with ID');
                        window.console.log(params.id);
                        window.console.groupEnd();
                    }
                }
            };
        },
        [buttonActionHandlers?.onRefund, spaModel]
    );
    const getAcceptRefund = useCallback(
        (params: GridRenderCellParams): DataGridActionProps => {
            const project = params.row as ProjectType & { projectId: string };
            const { clientInfo, projectId } = project!;
            const visible = isAcceptDeclinePageVisible(clientInfo);
            return {
                label: spaModel[LangAliasEnum.ACCEPT_BUTTON],
                visible,
                disabled: false,
                onClick: () => {
                    if (buttonActionHandlers?.onAcceptRefund) {
                        buttonActionHandlers.onAcceptRefund(projectId, clientInfo.user.id);
                    } else {
                        window.console.group('Refund accepted with ID');
                        window.console.log(projectId);
                        window.console.groupEnd();
                    }
                }
            };
        },
        [buttonActionHandlers?.onAcceptRefund, spaModel]
    );
    const getDeclineRefund = useCallback(
        (params: GridRenderCellParams): DataGridActionProps => {
            const project = params.row as ProjectType & { projectId: string };
            const { clientInfo, projectId } = project!;
            const visible = isAcceptDeclinePageVisible(clientInfo);
            return {
                label: spaModel[LangAliasEnum.DECLINE_BUTTON],
                visible,
                color: 'error',
                disabled: false,
                onClick: () => {
                    if (buttonActionHandlers?.onDeclineRefund) {
                        buttonActionHandlers.onDeclineRefund(projectId, clientInfo.user.id);
                    } else {
                        window.console.group('Refund declined with ID');
                        window.console.log(projectId);
                        window.console.groupEnd();
                    }
                }
            };
        },
        [buttonActionHandlers?.onDeclineRefund, spaModel]
    );
    const getActionByType = useCallback(
        (params: GridRenderCellParams, action: DataGridAction): DataGridActionProps => {
            switch (action) {
                case DataGridAction.EDIT:
                    return getEditButton(params);
                case DataGridAction.DELETE:
                    return getDeleteButton(params);
                case DataGridAction.LOOKUP:
                    return getLookUpButton(params);
                case DataGridAction.REFUND:
                    return getRefundButton(params);
                case DataGridAction.APPROVE_REFUND:
                    return getAcceptRefund(params);
                case DataGridAction.DECLINE_REFUND:
                    return getDeclineRefund(params);
                default:
                    return {
                        label: spaModel[LangAliasEnum.NOTHING_BUTTON],
                        onClick: () => {}
                    };
            }
        },
        [
            getEditButton,
            getDeleteButton,
            getLookUpButton,
            getRefundButton,
            getAcceptRefund,
            getDeclineRefund,
            spaModel
        ]
    );

    const getColumnsByPage = useCallback((): Array<DataGridColumn> => {
        switch (page) {
            case GridsEnum.LeadGridAdminColumns:
                return LEAD_GRID_ADMIN_COLUMNS;
            case GridsEnum.LeadGridClientColumns:
                return LEAD_GRID_CLIENT_COLUMNS;
            case GridsEnum.MembersGridColumns:
                return MEMBERS_GRID_COLUMNS;
            case GridsEnum.LeadApproveColumns:
                return LEAD_GRID_APPROVE_COLUMNS;
            case GridsEnum.MemberHistoryGridColumns:
                return MEMBERS_HISTORY_GRID_COLUMNS;
            default:
                return [] as Array<DataGridColumn>;
        }
    }, [page]);
    const getActionColumn = useCallback((): Array<DataGridColumn> => {
        if (buttons) {
            return [
                {
                    field: 'id',
                    headerName: columnHeader ?? '',
                    type: 'actions',
                    width: columnWidth || 100,
                    align: 'center',
                    headerAlign: 'center',
                    sortable: false,
                    renderCell: (params: GridRenderCellParams) => (
                        <Stack direction={'row'} spacing={0}>
                            {buttons.indexOf(DataGridAction.LOOKUP) > -1 && (
                                <CrudIconButton {...getActionByType(params, DataGridAction.LOOKUP)} />
                            )}
                            {buttons.indexOf(DataGridAction.EDIT) > -1 && (
                                <CrudIconButton {...getActionByType(params, DataGridAction.EDIT)} />
                            )}
                            {buttons.indexOf(DataGridAction.DELETE) > -1 && (
                                <CrudIconButton {...getActionByType(params, DataGridAction.DELETE)} />
                            )}
                            {buttons.indexOf(DataGridAction.REFUND) > -1 && (
                                <ComplexButton {...getActionByType(params, DataGridAction.REFUND)} />
                            )}
                            {buttons.indexOf(DataGridAction.APPROVE_REFUND) > -1 && (
                                <ComplexButton {...getActionByType(params, DataGridAction.APPROVE_REFUND)} />
                            )}
                            {buttons.indexOf(DataGridAction.DECLINE_REFUND) > -1 && (
                                <ComplexButton {...getActionByType(params, DataGridAction.DECLINE_REFUND)} />
                            )}
                        </Stack>
                    )
                }
            ] as Array<DataGridColumn>;
        }

        return [];
    }, [buttons, columnHeader, getActionByType]);

    const translatedColumns = useMemo(
        () =>
            getColumnsByPage().map((gridColumn) => ({
                ...gridColumn,
                headerName: spaModel[gridColumn.aliasName]
            })),
        [getColumnsByPage, spaModel]
    );

    const GRID_COLUMNS = useMemo(
        () => [...translatedColumns, ...getActionColumn()],
        [translatedColumns, getActionColumn]
    );
    return { GRID_COLUMNS };
};

export default useGridColumnService;
