import { LangModel } from 'reduxProvider/languageModelReducerProvider/languageModel.types';
import { applyLangModel } from 'reduxProvider/languageModelReducerProvider/languageModel';
import { AppDispatch, RootState } from '../config/configureStore';
import { useAppSelector } from './hook';

const langModelProvider = (state: RootState) => state.languageModel;
const selector: () => LangModel = () => useAppSelector(langModelProvider);
const applyLanguageModel = (dispatch: AppDispatch, newModel: LangModel) => {
    dispatch(applyLangModel(newModel));
};

export default { selector, applyLanguageModel };
