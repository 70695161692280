import { GridRenderCellParams } from '@mui/x-data-grid';
import { CustomerType } from 'pojo/customer';
import customerFunctions from 'pojo/customer/customerFunctions';
import React from 'react';
import { DataGridColumn } from 'global/component/CustomDataGrid/CustomDataGrid.types';
import CustomerInfoCell from 'utils/GridColumnsService/columns/common/CustomerInfoCell/CustomerInfoCell';
import { ProjectType } from 'pojo/project';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

const LEAD_GRID_ADMIN_COLUMNS = [
    {
        field: 'customer',
        headerName: 'Lead Info',
        aliasName: LangAliasEnum.LEAD_INFO_COLUMN_TITLE,
        flex: 0.5,
        minWidth: 250,
        align: 'left',
        headerAlign: 'left',
        valueGetter: (value) => {
            const customer = value as CustomerType;
            return customerFunctions.getFullName(customer);
        },
        renderCell: (params: GridRenderCellParams) => (
            <CustomerInfoCell fullName={params.value} createdAt={params.row.createdAt} />
        )
    },
    {
        field: 'industry',
        headerName: 'Industry',
        aliasName: LangAliasEnum.INDUSTRY_LABEL,
        width: 150,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'product',
        headerName: 'Product',
        aliasName: LangAliasEnum.PRODUCT_LABEL,
        width: 150,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'regionName',
        headerName: 'State',
        aliasName: LangAliasEnum.STATE_REGION_LABEL,
        minWidth: 90,
        flex: 0.1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (_, row) => {
            const project = row as ProjectType;
            return project.customer.person.locality.region.name;
        }
    },
    {
        field: 'customerEmail',
        headerName: 'Email',
        aliasName: LangAliasEnum.EMAIL_LABEL,
        flex: 0.1,
        minWidth: 250,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (_, row) => {
            const project = row as ProjectType;
            return project.customer.person.email;
        }
    },
    {
        field: 'customerPhoneNumber',
        headerName: 'Phone',
        aliasName: LangAliasEnum.PHONE_LABEL,
        width: 150,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (_, row) => {
            const project = row as ProjectType;
            return project.customer.person.phoneNumber;
        }
    }
] as Array<DataGridColumn>;

export default LEAD_GRID_ADMIN_COLUMNS;
