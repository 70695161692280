import React, { useMemo } from 'react';
import CriteriaForMemberHistory from 'global/component/Criteria/CriteriaForMemberHistory/CriteriaForMemberHistory';
import { CriteriaProps } from './Criteria.types';
import CriteriaForLead from './CriteriaForLead/CriteriaForLead';
import CriteriaForMembers from './CriteriaForMembers/CriteriaForMembers';
import './Criteria.scss';

const Criteria = ({ mode, criteria, onCriteriaChanged, extra }: CriteriaProps) => {
    const baseClass = useMemo(() => {
        switch (mode) {
            case 'lead':
                return ' criteria-for-lead';
            case 'member':
                return ' criteria-for-members';
            case 'member-history':
                return ' criteria-for-member-history';
            default:
                return '';
        }
    }, [mode]);

    return (
        <div className={`criteria${baseClass || ''}`}>
            {mode === 'lead' && (
                <CriteriaForLead extra={extra} onCriteriaChanged={onCriteriaChanged} criteria={criteria} />
            )}
            {mode === 'member' && (
                <CriteriaForMembers extra={extra} onCriteriaChanged={onCriteriaChanged} criteria={criteria} />
            )}
            {mode === 'member-history' && (
                <CriteriaForMemberHistory
                    extra={extra}
                    onCriteriaChanged={onCriteriaChanged}
                    criteria={criteria}
                />
            )}
        </div>
    );
};

export default Criteria;
