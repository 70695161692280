import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import useRequestService from 'utils/RequestService/RequestService';
import { IGlobalRequestService } from 'utils/GlobalRequestService/GlobalRequestService.types';
import { AxiosType } from 'utils/AxiosService/AxiosService.types';
import { langModelActions } from 'reduxProvider';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

const useGlobalRequestService = (): IGlobalRequestService => {
    const { spaModel } = langModelActions.selector();
    const requestWithSuccess = useRequestService(true, true, true);

    const deleteById = (id: string, basePath: string, callback?: () => void) => {
        requestWithSuccess
            .post(
                AxiosType.AUTH,
                `${basePath}/delete`,
                { id },
                spaModel[LangAliasEnum.DELETION_SUCCEEDED_MSG]
            )
            .then((resp: AxiosResponse) => {
                if (resp.data && callback) {
                    callback();
                }
            });
    };

    return useMemo(() => ({ deleteById }), [deleteById]);
};

export default useGlobalRequestService;
