import { langModelActions, loadingActions, useAppDispatch } from 'reduxProvider';
import { useGridApiRef } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { CriteriaMemberHistory } from 'global/component/Criteria/CriteriaForMemberHistory/CriteriaForMemberHistory.types';
import moment from 'moment/moment';
import { Paging } from 'index.types';
import { useGridColumnService, useLoadDataGridService } from 'utils';
import { GridsEnum } from 'utils/GridColumnsService/GridColumnsService.types';
import { Criteria, ListViewMode } from 'global/component';
import { IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { UserStatisticProps, UserStatisticType } from 'common/UserStatistic/UserStatistic.types';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

const UserStatistic = ({ member }: UserStatisticProps) => {
    const { spaModel } = langModelActions.selector();
    const dispatch = useAppDispatch();
    const { status } = loadingActions.selector();

    const gridApiRef = useGridApiRef();
    const [criteria, setCriteria] = useState<CriteriaMemberHistory>({
        fromDateAssigned: undefined,
        toDateAssigned: encodeURIComponent(moment().add(1, 'days').toISOString(true))
    });
    const [rowCount, setRowCount] = useState<number>(0);
    const [gridData, setGridData] = useState<Array<UserStatisticType>>([]);
    const [paging, setPaging] = useState<Paging>({
        page: 0,
        pageSize: undefined
    });

    const { GRID_COLUMNS } = useGridColumnService(GridsEnum.MemberHistoryGridColumns);
    const { onCriteriaChangeHandle, onPageChangeHandle } = useLoadDataGridService(
        '/user/statistic',
        criteria,
        paging,
        setRowCount,
        (el) => el,
        setGridData,
        (loadingStatus) => (loadingStatus ? loadingActions.show(dispatch) : loadingActions.hide(dispatch)),
        undefined,
        () => !!criteria.id
    );
    useEffect(() => {
        setCriteria((prev) => ({
            ...prev,
            id: member!.id
        }));
    }, [member?.id]);
    useEffect(onPageChangeHandle, [paging, setPaging]);
    useEffect(onCriteriaChangeHandle, [criteria, setCriteria]);

    const onExportGrid = useCallback(() => {
        gridApiRef.current.exportDataAsCsv({
            fileName: spaModel[LangAliasEnum.USER_STATISTIC],
            delimiter: ';',
            utf8WithBom: true,
            allColumns: true
        });
    }, [gridApiRef, spaModel]);

    return (
        <div className={'phase-view-mode general-admin-list'}>
            <div className={'list-visualisation'}>
                <div className={'criteria-holder'}>
                    <Criteria
                        onCriteriaChanged={(newCriteria: CriteriaMemberHistory) =>
                            setCriteria((prev) => ({ ...prev, ...newCriteria }))
                        }
                        criteria={criteria}
                        mode={'member-history'}
                    />
                    <div className={'up-buttons'}>
                        <div className={'up-button'}>
                            <IconButton
                                size={'large'}
                                aria-label={spaModel[LangAliasEnum.EXPORT_BUTTON]}
                                onClick={onExportGrid}
                            >
                                <FileDownloadIcon fontSize={'inherit'} />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className={'data'}>
                    <ListViewMode
                        apiRef={gridApiRef}
                        columns={GRID_COLUMNS}
                        rowCount={rowCount}
                        data={gridData}
                        onPaginationModelChanged={(pageInfo: Paging) => setPaging({ ...pageInfo })}
                        onPageChanged={(page: number) => setPaging((prev) => ({ ...prev, page }))}
                        onPageSizeChanged={(pageSize: number) =>
                            setPaging((prev) => ({
                                ...prev,
                                pageSize
                            }))
                        }
                        loading={status > 0}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserStatistic;
