import { MuiTelInput, MuiTelInputInfo, MuiTelInputProps } from 'mui-tel-input';
import React, { useCallback } from 'react';

const PhoneNumberInput = React.forwardRef(
    ({ onChange, ...props }: MuiTelInputProps, _ref: React.ForwardedRef<HTMLDivElement>) => {
        const handleChange = useCallback(
            (_value: string, info: MuiTelInputInfo) => {
                const { numberValue } = info;
                if (onChange) {
                    onChange(
                        numberValue === '+52' ? (null as never as string) : (numberValue as never as string),
                        info
                    );
                }
            },
            [onChange]
        );

        return (
            <MuiTelInput
                {...props}
                inputRef={_ref}
                onChange={handleChange}
                defaultCountry={'MX'}
                disableDropdown={true}
                forceCallingCode={true}
            />
        );
    }
);

export default PhoneNumberInput;
