import { FieldError } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import { Error } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { ErrorTooltip } from '../index';

const ErrorAdornment = ({
    fieldError,
    withAdornment
}: {
    fieldError?: FieldError;
    withAdornment?: boolean;
}) => {
    const withAdornmentDefault = useMemo(() => {
        if (withAdornment === undefined) {
            return true;
        }
        return withAdornment;
    }, [withAdornment]);

    return (
        <>
            {!!fieldError && (
                <>
                    {withAdornmentDefault && (
                        <InputAdornment position={'end'}>
                            <ErrorTooltip title={fieldError?.message} arrow={true}>
                                <Error color={'error'} />
                            </ErrorTooltip>
                        </InputAdornment>
                    )}
                    {!withAdornmentDefault && (
                        <ErrorTooltip title={fieldError?.message} arrow={true}>
                            <Error color={'error'} />
                        </ErrorTooltip>
                    )}
                </>
            )}
        </>
    );
};

export default ErrorAdornment;
