import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
import { Bundle, PageUrl } from 'index.types';
import { GroupPages as ANONYMOUS_GROUP_PAGES, Layout as AnonymousLayout } from 'anonymous';
import { LEAD_PARAMS } from 'common/Lead/index.url';
import { ADMIN_PAGES_URL } from 'admin/index.url';
import { CLIENT_PAGES_URL } from 'client/index.url';
import { Layout as CommonLoggedInLayout } from 'common';
import { GroupPages as ADMIN_GROUP_PAGES, LayoutConfig as ADMIN_LAYOUT_CONFIG } from 'admin';
import { GroupPages as CLIENT_GROUP_PAGES, LayoutConfig as CLIENT_LAYOUT_CONFIG } from 'client';
import { ANON_PAGES_URLS } from 'anonymous/index.url';
import { withCommonPage } from 'global/abstraction';
import SuspenseComponent from './SuspenseComponent';

const MainMenu = () => {
    const menuRender = useCallback(
        (items: Array<PageUrl> | undefined, bundle: Bundle): Array<RouteObject> => {
            if (items) {
                return items.map(({ routingUrl, Component, children }: PageUrl) => {
                    if (children) {
                        return {
                            path: routingUrl,
                            children: menuRender(children, bundle)
                        } as RouteObject;
                    }

                    if (Component) {
                        const ComponentWithBundle = withCommonPage(bundle, Component);
                        return {
                            path: routingUrl,
                            element: <SuspenseComponent Component={ComponentWithBundle} data={undefined} />
                        } as RouteObject;
                    }

                    return {
                        path: routingUrl
                    } as RouteObject;
                });
            }

            return [];
        },
        []
    );

    const routesConfig: Array<RouteObject> = useMemo(
        () => [
            { path: '/', element: <Navigate to={ANON_PAGES_URLS.AUTH} /> },
            {
                path: `/${Bundle.ADMIN}`,
                element: <Navigate to={ADMIN_PAGES_URL.LEAD.LIST} />
            },
            {
                path: `/${Bundle.ADMIN}/${LEAD_PARAMS.GENERAL_URL}`,
                element: <Navigate to={ADMIN_PAGES_URL.LEAD.LIST} />
            },
            {
                path: `/${Bundle.CLIENT}`,
                element: <Navigate to={CLIENT_PAGES_URL.LEAD.LIST} />
            },
            {
                path: `/${Bundle.CLIENT}/${LEAD_PARAMS.GENERAL_URL}`,
                element: <Navigate to={CLIENT_PAGES_URL.LEAD.LIST} />
            },
            {
                element: <SuspenseComponent Component={AnonymousLayout} data={undefined} />,
                children: menuRender(ANONYMOUS_GROUP_PAGES, Bundle.ANONYMOUS)
            },
            {
                element: <SuspenseComponent Component={CommonLoggedInLayout} data={ADMIN_LAYOUT_CONFIG} />,
                children: menuRender(ADMIN_GROUP_PAGES, Bundle.ADMIN)
            },
            {
                element: <SuspenseComponent Component={CommonLoggedInLayout} data={CLIENT_LAYOUT_CONFIG} />,
                children: menuRender(CLIENT_GROUP_PAGES, Bundle.CLIENT)
            }
        ],
        [menuRender]
    );

    return useRoutes(routesConfig);
};

export default MainMenu;
