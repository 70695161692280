import { DataGridColumn } from 'global/component/CustomDataGrid/CustomDataGrid.types';
import React from 'react';

export enum DataGridAction {
    EDIT = 'edit',
    DELETE = 'delete',
    LOOKUP = 'lookup',
    REFUND = 'refund',
    APPROVE_REFUND = 'approve_refund',
    DECLINE_REFUND = 'decline_refund'
}

export type DataGridActionProps = {
    label: string;
    color?: 'success' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' | undefined;
    onClick?: () => void;
    Icon?: React.ElementType;
    disabled?: boolean;
    visible?: boolean;
};

export type DataGridActionHandlers = {
    onEdit?: (id: string) => void;
    onDelete?: (id: string) => void;
    onLookUp?: (id: string) => void;
    onRefund?: (id: string) => void;
    onAcceptRefund?: (id: string, clientUserId: string) => void;
    onDeclineRefund?: (id: string, clientUserId: string) => void;
};

export enum GridsEnum {
    LeadGridAdminColumns,
    LeadGridClientColumns,
    LeadApproveColumns,
    MembersGridColumns,
    MemberHistoryGridColumns
}

export type GridColumnsService = {
    GRID_COLUMNS: Array<DataGridColumn>;
};
