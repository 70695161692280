import { array, boolean, number, object, string } from 'zod';
import { RoleSchema } from 'pojo/role';
import { GoalSchema } from 'pojo/goal';

export default object({
    id: string(),
    createdAt: string(),
    isActive: boolean(),
    industry: string(),
    products: array(string()),
    countLeadsPerDay: number()
        .positive('El recuento de clientes potenciales por Día debe ser positivo')
        .default(0),
    pricePerLead: number().positive('El precio por Lead debería ser positivo').default(1),
    isExclusive: boolean().default(false),
    role: RoleSchema.refine((val) => !!val && !!val?.id, 'Se requiere rol'),
    goals: array(GoalSchema)
});
