import { object, string } from 'zod';
import { RegionSchema } from 'pojo/region';

export default object({
    id: string({
        invalid_type_error: 'Se requiere ciudad',
        required_error: 'Se requiere ciudad'
    }),
    lat: string(),
    lon: string(),
    name: string(),
    region: RegionSchema
});
