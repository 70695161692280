import { array, number, object, z } from 'zod';
import { TeamSchema } from 'pojo/team';
import { ProfileSchema } from 'pojo/profile';
import { UserRolesEnum } from 'index.types';
import UserCompactSchema from './UserCompactSchema';

const INDUSTRY_IS_REQUIRED = {
    code: z.ZodIssueCode.custom,
    message: 'Se requiere industria',
    path: ['industry']
};

const PRODUCT_IS_REQUIRED = {
    code: z.ZodIssueCode.custom,
    message: 'Se requiere cualquier producto',
    path: ['products']
};

const COUNT_LEADS_PER_DAY_IS_REQUIRED = {
    code: z.ZodIssueCode.custom,
    message: 'Se requiere contar clientes potenciales por Día',
    path: ['countLeadsPerDay']
};

const PRICE_PER_LEAD_IS_REQUIRED = {
    code: z.ZodIssueCode.custom,
    message: 'Se requiere precio por Lead',
    path: ['pricePerLead']
};

const IS_EXCLUSIVE_IS_REQUIRED = {
    code: z.ZodIssueCode.custom,
    message: 'Se requiere exclusividad',
    path: ['isExclusive']
};

const UserExtendedSchema = object({
    availableFunds: number(),
    profile: ProfileSchema.superRefine((val, ctx) => {
        if (val?.role?.name === UserRolesEnum.Client) {
            if (!val.industry) {
                ctx.addIssue(INDUSTRY_IS_REQUIRED);
            }
            if (!val.products || val.products.length === 0) {
                ctx.addIssue(PRODUCT_IS_REQUIRED);
            }
            if (!val.countLeadsPerDay) {
                ctx.addIssue(COUNT_LEADS_PER_DAY_IS_REQUIRED);
            }
            if (!val.pricePerLead) {
                ctx.addIssue(PRICE_PER_LEAD_IS_REQUIRED);
            }
            if (val.isExclusive === undefined || val.isExclusive === null) {
                ctx.addIssue(IS_EXCLUSIVE_IS_REQUIRED);
            }
        }
    }),
    teams: array(TeamSchema, {
        invalid_type_error: 'Se requiere equipo',
        required_error: 'Se requiere equipo'
    }).nonempty('Se debe elegir al menos 1 equipo')
});
const schema = UserCompactSchema.merge(UserExtendedSchema);
export default schema;
