import { useCallback, useMemo } from 'react';
import { contextActions } from 'reduxProvider';
import { UserRolesEnum } from 'index.types';

const useSecurityValidator = () => {
    const { user } = contextActions.selector();

    const activeRoleName = useMemo(() => {
        // const activeProfile = user.profiles.find(({ isActive }) => isActive);
        const activeProfile = user.profile;
        return activeProfile?.role?.name as UserRolesEnum | undefined;
    }, [user]);

    const isRoleValid = useCallback(
        (role: 'all' | Array<UserRolesEnum>) =>
            activeRoleName && ((Array.isArray(role) && role.indexOf(activeRoleName) >= 0) || role === 'all'),
        [activeRoleName]
    );

    return useMemo(() => ({ isRoleValid }), [isRoleValid]);
};

export default useSecurityValidator;
