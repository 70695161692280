import React, { useCallback, useMemo, useState } from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';
import { SelectButtonProps } from './SelectButton.types';
import { MENU_ANCHOR_ORIGIN, MENU_TRANSFORM, SELECT_BUTTON_TOOLTIP_STYLES } from './SelectButton.styles';

const SelectButton = ({
    mode,
    menuItems,
    children,
    title,
    color,
    variant,
    EndIcon,
    disabled
}: SelectButtonProps) => {
    const [selectButton, setSelectButton] = useState<null | HTMLElement>(null);
    const openSelectButton = Boolean(selectButton);

    const onChangeSelectButtonVisibility = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setSelectButton(event.currentTarget);
    }, []);
    const onCloseSelectButton = useCallback(() => {
        setSelectButton(null);
    }, []);

    const Component: React.ElementType = useMemo(() => {
        switch (mode) {
            case 'button': {
                return Button;
            }
            case 'icon-button': {
                return IconButton;
            }
            default: {
                return Button;
            }
        }
    }, [mode]);
    const componentProps = useMemo(() => {
        let props: object = {
            disabled: !!disabled,
            className: 'account-settings-container',
            onClick: onChangeSelectButtonVisibility,
            'aria-controls': openSelectButton ? 'account-menu' : undefined,
            'aria-haspopup': true,
            'aria-expanded': openSelectButton ? 'true' : undefined
        };
        if (mode === 'button') {
            if (color) {
                props = {
                    ...props,
                    color
                };
            }
            if (variant) {
                props = {
                    ...props,
                    variant
                };
            }
            if (EndIcon) {
                props = {
                    ...props,
                    endIcon: <EndIcon />
                };
            }
        }
        return props;
    }, [onChangeSelectButtonVisibility, openSelectButton, EndIcon, variant, color]);

    return (
        <>
            <Tooltip title={title}>
                <Component {...componentProps}>{children}</Component>
            </Tooltip>
            <Menu
                anchorEl={selectButton}
                id={'account-menu'}
                open={openSelectButton}
                onClose={onCloseSelectButton}
                onClick={onCloseSelectButton}
                PaperProps={SELECT_BUTTON_TOOLTIP_STYLES}
                anchorOrigin={MENU_ANCHOR_ORIGIN}
                transformOrigin={MENU_TRANSFORM}
            >
                {menuItems}
            </Menu>
        </>
    );
};

export default SelectButton;
