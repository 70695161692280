import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LangModel } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

const initialState: LangModel = {
    spaModel: {
        LOG_IN_INFO_MSG: 'Log into your account',
        FORGET_PASSWORD_INFO_MSG:
            'Enter your email and we will send you a new password to regain access to your account.',
        ADDRESS_INFO_INFO_MSG: 'Address information',
        GENERAL_INFO_INFO_MSG: 'General Information',
        CONTACT_INFO_INFO_MSG: 'Contact Information',
        VISIT_INFO_INFO_MSG: 'Visit information',
        REQ_FIELDS_INFO_MSG: '* The following must be completed mandatory',
        MARKETING_INF_INF_MSG: 'Marketing Information',
        TOGGLE_PWD_VISIBILITY: 'toggle password visibility',
        NO_PERMISSION_MSG: 'You have not permission to perform with action',
        DELETION_SUCCEEDED_MSG: 'Deletion succeeded',
        FAILED_TO_CONNECT_TO_SERVER_MSG: 'Impossible to identify server',

        MY_PROFILE_TITLE: 'My Profile',
        LEADS_TITLE: 'Leads',
        NEW_LEAD_TITLE: 'New Lead',
        EDIT_LEAD_TITLE: 'Edit lead',
        STATISTIC_TITLE: 'Statistic',
        MEMBERS_TITLE: 'Members',
        NEW_MEMBER_TITLE: 'New Member',
        EDIT_MEMBER_TITLE: 'Edit member',
        REFUND_REQUEST_TITLE: 'Refund Request',

        ACTION_LEAD_COLUMN_TITLE: 'Action',
        LEAD_INFO_COLUMN_TITLE: 'Lead Info',
        CLIENT_INFO_COLUMN_TITLE: 'Client Info',
        REFUND_REASON_COLUMN_TITLE: 'Refund Reason',
        DATE_ASSIGNED_COLUMN_TITLE: 'Date Assigned',
        CONTRACT_COLUMN_TITLE: 'Contract',
        DELIVERED_COLUMN_TITLE: 'Delivered',
        BILLABLE_COLUMN_TITLE: 'Billable',
        AVG_PRICE_COLUMN_TITLE: 'Avg Price',
        SPENT_COLUMN_TITLE: 'Spent',
        RETURN_RATE_COLUMN_TITLE: 'Return Rate',
        RETURNED_COLUMN_TITLE: 'Returned',
        MEMBER_INFO_COLUMN_TITLE: 'Member Info',

        CREATE_LEAD_DATE_TIME_LABEL: 'Income Lead',
        NAME_LABEL: 'Name',
        FROM_LABEL: 'From',
        TO_LABEL: 'To',
        LEAD_FULL_NAME_LABEL: 'Lead Full Name',
        COMPANY_NAME_LABEL: 'Company Name',
        LEAD_SOURCE_LABEL: 'Lead Source',
        EMAIL_LABEL: 'Email',
        ADDRESS_LABEL: 'Address',
        PLATFORM_LABEL: 'Platform',
        LANDING_PAGE_LABEL: 'Landing page',
        ROLE_LABEL: 'Role',
        REASON_LABEL: 'Reason',
        NOTE_LABEL: 'Note',
        CREATION_DATE_LABEL: 'Creation date',
        STATUS_LABEL: 'Status',
        PRICE_LABEL: 'Price',
        DESCRIPTION_LABEL: 'Description',
        PRODUCT_LABEL: 'Product',
        STATE_REGION_LABEL: 'Region',
        CITY_LOCALITY_LABEL: 'Locality',
        PASSWORD_LABEL: 'Password',
        FIRST_NAME_LABEL: 'First Name',
        LAST_NAME_LABEL: 'Last Name',
        PHONE_LABEL: 'Phone',
        STREET_ADDRESS_LABEL: 'Street Address',
        STREET_ADDRESS_TWO_LABEL: 'Street Address 2',
        POSTAL_CODE_LABEL: 'Postal code',
        CAMPAIGN_LABEL: 'Campaign',
        KEY_WORD_LABEL: 'Key Word',
        ADD_MARKETING_LABEL: 'Add',
        GCLID_LABEL: 'GCLID',
        CURRENT_PASSWORD_LABEL: 'Current Password',
        NEW_PASSWORD_LABEL: 'New Password',
        CONFIRM_PASSWORD_LABEL: 'Confirm Password',
        INDUSTRY_LABEL: 'Industry',
        SUBSCRIBES_PRODUCTS_LABEL: 'Subscribes products',
        AVAILABLE_FUNDS_LABEL: 'Available Funds',
        COUNT_LEADS_PER_DAY_LABEL: 'Count Leads per Day',
        PRICE_PER_LEAD_LABEL: 'Price per Lead',
        IS_EXCLUSIVE_LABEL: 'Is Exclusive',
        DELIVERED_TODAY_LABEL: 'Delivered Today',
        DELIVERED_DAY_LIMIT_LABEL: 'Delivered Day Limit',
        SPEND_TODAY_LABEL: 'Spend Today',
        CREDIT_REMAINING_LABEL: 'Credit Remaining',
        MONTH_RETURNS_RATE_LABEL: 'Month Returns Rate',
        PENDING_LABEL: 'Pending',

        SEARCH_BY_NAME_PLACEHOLDER: 'Search by name',
        SEARCH_LEAD_PLACEHOLDER: 'Search lead',
        COUNT_LEADS_PER_DAY_PLACEHOLDER: 'Enter count leads per day',
        GCLID_PLACEHOLDER: 'Enter gclid',
        ADD_MARKETING_PLACEHOLDER: 'Enter add',
        KEY_WORD_PLACEHOLDER: 'Enter key word',
        CAMPAIGN_PLACEHOLDER: 'Enter campaign',
        PLATFORM_PLACEHOLDER: 'Enter platform',
        LANDING_PAGE_PLACEHOLDER: 'Enter landing page',
        LEAD_SOURCE_PLACEHOLDER: 'Enter lead source',
        EMAIL_PLACEHOLDER: 'Enter email',
        STATE_REGION_PLACEHOLDER: 'Enter region',
        CITY_LOCALITY_PLACEHOLDER: 'Enter locality',
        PHONE_PLACEHOLDER: 'Enter a phone number',
        REFUND_NOTE_PLACEHOLDER: 'Enter refund note',
        DESCRIPTION_PLACEHOLDER: 'Enter description',
        FIRST_NAME_PLACEHOLDER: 'Enter first name',
        LAST_NAME_PLACEHOLDER: 'Enter last name',
        STREET_ADDRESS_PLACEHOLDER: 'Enter street address',
        STREET_ADDRESS_TWO_PLACEHOLDER: 'Enter street address 2',
        POSTAL_CODE_PLACEHOLDER: 'Enter postal code',

        CONTACTS_TAB_TITLE: 'Contacts',
        PERSONAL_DETAILS_TAB_TITLE: 'Personal Details',
        CHANGE_PASSWORD_TAB_TITLE: 'Change Password',
        CLIENT_SETTINGS_TAB_TITLE: 'Client Settings',
        ACCOUNT_HISTORY_TAB_TITLE: 'Account History',
        INFO_TAB_TITLE: 'Info',

        REFUND_NOTE_ACCORDION_TITLE: 'Refund Note',

        LIST_VIEW_MODE_BUTTON: 'List',
        ASSIGNED_VIEW_MODE_BUTTON: 'Assigned',

        FORGET_PASSWORD_BUTTON: 'Forgot password',
        LOOKUP_BUTTON: 'LookUp',
        DELETE_BUTTON: 'Delete',
        EDIT_BUTTON: 'Edit',
        REFUND_BUTTON: 'Refund',
        ACCEPT_BUTTON: 'Accept',
        DECLINE_BUTTON: 'Decline',
        NOTHING_BUTTON: 'Nothing',
        LOGIN_BUTTON: 'Login',
        RESET_BUTTON: 'Reset',
        CLOSE_BUTTON: 'Close',
        CREATE_BUTTON: 'Create',
        REQUEST_BUTTON: 'Request',
        CALL_BUTTON: 'Call',
        SAVE_BUTTON: 'Save',
        CHANGE_STATUS_BUTTON: 'Change status',
        EXPORT_BUTTON: 'Export',
        CANCEL_BUTTON: 'Cancel',
        UPDATE_BUTTON: 'Update',
        CLOSE_PROJECT_BUTTON: 'Close project',
        RESET_PASSWORD_BUTTON: 'Reset password',
        MY_ACCOUNT_BUTTON: 'My account',
        LOGOUT_BUTTON: 'Logout',
        BACK_BUTTON: 'Back',
        REFRESH_DATA_BUTTON: 'Refresh data',
        CHANGE_PASSWORD_BUTTON: 'Change Password',
        UPLOAD_BUTTON_AVATAR: 'Upload new avatar',

        USER_STATISTIC: 'UserStatistic' // it's correct without space
    }
};

export const languageModelSlice = createSlice({
    name: 'languageModel',
    initialState,
    reducers: {
        applyLangModel: (state, action: PayloadAction<LangModel>) => {
            if (process.env.NODE_ENV === 'production') {
                state.spaModel = {
                    ...state.spaModel,
                    ...action.payload.spaModel
                };
            }
        }
    }
});

export const { applyLangModel } = languageModelSlice.actions;

export default languageModelSlice.reducer;
