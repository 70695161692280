import { applyMiddleware, configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import logger from 'redux-logger';
import promise from 'redux-promise-middleware';
import { thunk } from 'redux-thunk';
import loadingReducerProvider from '../loadingReducerProvider';
import contextReducerProvider from '../contextReducerProvider';
import languageModelReducerProvider from '../languageModelReducerProvider';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
window._dev = {
    showDevTools: false,
    showLogger: false
};
const { NODE_ENV } = process.env;

const middleware = [thunk, promise];
const rootReducer = combineReducers({
    loading: loadingReducerProvider.reducer,
    context: contextReducerProvider.reducer,
    languageModel: languageModelReducerProvider.reducer
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        if (window._dev.showLogger) {
            return getDefaultMiddleware().concat(logger);
        }
        return getDefaultMiddleware();
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    devTools: NODE_ENV !== 'production' && window._dev.showDevTools,
    ...applyMiddleware(...middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
