import { UserType } from 'pojo/user';
import { Context } from 'reduxProvider/contextReducerProvider/context.types';
import { KeyValueType } from 'pojo/common';
import { loadingActions } from 'reduxProvider/index';
import { AppDispatch, RootState } from '../config/configureStore';
import { useAppSelector } from './hook';
import { applyRoleEnumToContext, applyUserToContext, logoutUser } from '../contextReducerProvider/context';

const contextProvider = (state: RootState) => state.context;
const selector: () => Context = () => useAppSelector(contextProvider);
const applyUserContext = (dispatch: AppDispatch, user: UserType) => {
    dispatch(applyUserToContext(user));
    loadingActions.changeLoggedInMode(dispatch, true);
};
const applyRoleEnumContext = (dispatch: AppDispatch, roleEnum: Array<KeyValueType>) => {
    dispatch(applyRoleEnumToContext(roleEnum));
};
const logout = (dispatch: AppDispatch) => {
    dispatch(logoutUser());
    loadingActions.changeLoggedInMode(dispatch, false);
};

export default { selector, logout, applyUserContext, applyRoleEnumContext };
