import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { AxiosType, IAxiosService } from 'utils/AxiosService/AxiosService.types';
import { useCallback, useMemo } from 'react';
import { XSRF_COOKIE_NAME, XSRF_HEADER_NAME } from 'global/globalSecurity';

const { REACT_APP_API } = process.env;

axios.defaults.baseURL = REACT_APP_API;
axios.defaults.responseType = 'json';

axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers.Accept = 'application/json';

axios.defaults.headers['Access-Control-Allow-Origin'] = REACT_APP_API as string;
axios.defaults.headers['Access-Control-Allow-Methods'] = 'GET,POST,PUT,DELETE,OPTIONS';
axios.defaults.headers['Access-Control-Allow-Headers'] = '*';
axios.defaults.withCredentials = true;

const anonymousAxios: AxiosInstance = axios.create();
const authAxios: AxiosInstance = axios.create();

const onRequestAuthenticated = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    config.withCredentials = true;
    config.xsrfCookieName = XSRF_COOKIE_NAME;
    config.xsrfHeaderName = XSRF_HEADER_NAME;

    return config;
};

authAxios.interceptors.request.use(onRequestAuthenticated);

const useAxiosService = (): IAxiosService => {
    const getAxiosInstance = useCallback((axiosType: AxiosType) => {
        switch (axiosType) {
            case AxiosType.ANON:
                return anonymousAxios;
            case AxiosType.AUTH:
                return authAxios;
            default:
                return axios.create(); // non-reachable code
        }
    }, []);

    return useMemo(
        () => ({
            anonymousAxios,
            authAxios,
            getAxiosInstance
        }),
        [getAxiosInstance]
    );
};

export default useAxiosService;
