import { UserSecurityTypeEnum } from 'global/globalSecurity';
import { ANON_PAGES_URLS } from 'anonymous/index.url';
import { ADMIN_PAGES_URL } from 'admin/index.url';
import { CLIENT_PAGES_URL } from 'client/index.url';

const defaultPageUrl = (userType: UserSecurityTypeEnum) => {
    switch (userType) {
        case UserSecurityTypeEnum.ADMIN:
            return ADMIN_PAGES_URL.LEAD.LIST;
        case UserSecurityTypeEnum.CLIENT:
            return CLIENT_PAGES_URL.LEAD.LIST;
        default:
            return ANON_PAGES_URLS.AUTH;
    }
};

export { defaultPageUrl };
