import { number, object, string } from 'zod';

export default object({
    id: string({
        invalid_type_error: 'El rol es obligatorio',
        required_error: 'El rol es obligatorio'
    }),
    createdAt: string(),
    name: string(),
    awardsAmount: number()
});
