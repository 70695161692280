import { Bundle, PageUrl } from 'index.types';
import { guidGenerator } from 'global/globalFunctions';
import { LeadPages, MyProfileUrl } from 'common/index.routing';
import { StatisticUrl } from 'client/Statistic/index.routing';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

const ClientAuthGroupPages: PageUrl = {
    id: guidGenerator(),
    name: 'Client pages',
    aliasName: LangAliasEnum.UNUSED,
    redirectUrl: `/${Bundle.CLIENT}`,
    routingUrl: `/${Bundle.CLIENT}`,
    role: 'all',
    children: [LeadPages, MyProfileUrl, StatisticUrl]
};

const InMenuPages = [LeadPages, StatisticUrl];

export { ClientAuthGroupPages, InMenuPages };
