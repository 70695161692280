import { BundleUrls } from 'index.types';
import { LEAD_PARAMS, LEAD_URLS } from './Lead/index.url';
import { MYPROFILE_URLS } from './MyProfile/index.url';

const COMMON_PAGES_URL: BundleUrls = {
    LEAD: {
        NEW: `${LEAD_PARAMS.GENERAL_URL}/${LEAD_URLS.LEAD_NEW}`,
        LIST: `${LEAD_PARAMS.GENERAL_URL}/${LEAD_URLS.LEAD_LIST}`,
        EDIT: `${LEAD_PARAMS.GENERAL_URL}/${LEAD_URLS.LEAD_EDIT}/${LEAD_PARAMS.ID_REPLACEMENT_PARAM}`,
        MEMO: LEAD_PARAMS.GENERAL_URL.toUpperCase()
    },
    PROFILE: {
        MY: `${MYPROFILE_URLS.MY}`
    }
};

export { LEAD_PARAMS, COMMON_PAGES_URL };
