import { store } from 'reduxProvider';
import { ThemeProvider } from '@mui/material';
import theme from 'theme';
import { CookiesProvider } from 'react-cookie';
import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Provider } from 'react-redux';
import React from 'react';
import { LocalizationProviderProps } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { Moment } from 'moment';
import App from './App/App';

const SNACKBAR_CONFIGURATION: SnackbarProviderProps = {
    anchorOrigin: {
        horizontal: 'right',
        vertical: 'bottom'
    },
    autoHideDuration: 2000,
    maxSnack: 3
};
const LOCALE_CONFIGURATION: LocalizationProviderProps<Moment, string> = {
    dateAdapter: AdapterMoment,
    adapterLocale: 'de'
};

const Root = () => (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <CookiesProvider>
                <SnackbarProvider {...SNACKBAR_CONFIGURATION}>
                    <LocalizationProvider {...LOCALE_CONFIGURATION}>
                        <App />
                    </LocalizationProvider>
                </SnackbarProvider>
            </CookiesProvider>
        </ThemeProvider>
    </Provider>
);

export default Root;
