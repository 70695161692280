import React from 'react';
import { FormControl, Typography } from '@mui/material';
import { UpdatableFormControlProps } from './UpdatableFormControl.types';

const UpdatableFormControl = ({ label, children }: UpdatableFormControlProps) => (
    <FormControl fullWidth={true}>
        {label && (
            <Typography fontStyle={'italic'} fontWeight={600}>
                {label}
            </Typography>
        )}
        <div className={'row-components'}>{children}</div>
    </FormControl>
);

export default UpdatableFormControl;
