import { AxiosInstance } from 'axios';

export enum AxiosType {
    ANON = '_anon',
    AUTH = '_auth'
}

export interface IAxiosService {
    readonly anonymousAxios: AxiosInstance;
    readonly authAxios: AxiosInstance;
    readonly getAxiosInstance: (type: AxiosType) => AxiosInstance;
}
