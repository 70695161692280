import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from './loading.types';

const initialState: LoadingStatus = {
    status: 0,
    loggedIn: false
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        showLoading: (state) => {
            state.status += 1;
        },
        hideLoading: (state) => {
            if (state.status > 0) {
                state.status -= 1;
            }
        },
        loggedInMode: (state, action: PayloadAction<boolean>) => {
            state.loggedIn = action.payload;
        }
    }
});

export const { showLoading, hideLoading, loggedInMode } = loadingSlice.actions;

export default loadingSlice.reducer;
