import { ClientProjectStatusEnum } from 'index.types';
import { ClientInfoType } from 'pojo/project';
import moment from 'moment/moment';

const isRefundColumnVisible = (clientInfo: ClientInfoType) => {
    const now = moment(moment.now());
    const dateAssigned = moment(clientInfo.dateAssigned);
    return (
        (now.diff(dateAssigned, 'days') + 1 < 7 ||
            clientInfo.status === ClientProjectStatusEnum.RETURN_PENDING) &&
        clientInfo.status !== ClientProjectStatusEnum.RETURN_APPROVED &&
        clientInfo.status !== ClientProjectStatusEnum.RETURN_DECLINED
    );
};

const isAcceptDeclinePageVisible = (clientInfo: ClientInfoType) =>
    clientInfo?.status === ClientProjectStatusEnum.RETURN_PENDING;

export { isRefundColumnVisible, isAcceptDeclinePageVisible };
