import { GridRenderCellParams } from '@mui/x-data-grid';
import { CustomerType } from 'pojo/customer';
import customerFunctions from 'pojo/customer/customerFunctions';
import React from 'react';
import { DataGridColumn } from 'global/component/CustomDataGrid/CustomDataGrid.types';
import CustomerInfoCell from 'utils/GridColumnsService/columns/common/CustomerInfoCell/CustomerInfoCell';
import { userFunctions } from 'pojo/user';
import { ProjectType } from 'pojo/project';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';
import RenderExpandableCell from './RenderExpandableCell/RenderExpandableCell';

const LEAD_GRID_APPROVE_COLUMNS = [
    {
        field: 'customer',
        headerName: 'Lead Info',
        aliasName: LangAliasEnum.LEAD_INFO_COLUMN_TITLE,
        flex: 0.1,
        minWidth: 225,
        align: 'left',
        headerAlign: 'left',
        valueGetter: (value) => {
            const customer = value as CustomerType;
            return customerFunctions.getFullName(customer);
        },
        renderCell: (params: GridRenderCellParams) => (
            <CustomerInfoCell fullName={params.value} createdAt={params.row.createdAt} />
        )
    },
    {
        field: 'clientInfoFullName',
        headerName: 'Client Info',
        aliasName: LangAliasEnum.CLIENT_INFO_COLUMN_TITLE,
        flex: 0.2,
        minWidth: 225,
        align: 'left',
        headerAlign: 'left',
        valueGetter: (_, row) => {
            const { clientInfo } = row as ProjectType;
            return clientInfo ? userFunctions.getFullNameByCompactType(clientInfo.user) : '';
        },
        renderCell: (params: GridRenderCellParams) => (
            <CustomerInfoCell fullName={params.value} createdAt={params.row.createdAt} />
        )
    },
    {
        field: 'clientInfoCompanyName',
        headerName: 'Company Name',
        aliasName: LangAliasEnum.COMPANY_NAME_LABEL,
        width: 200,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (_, row) => {
            const { clientInfo } = row as ProjectType;
            return clientInfo ? clientInfo.user.companyName : '';
        }
    },
    {
        field: 'clientInfoStatus',
        headerName: 'Status',
        aliasName: LangAliasEnum.STATUS_LABEL,
        width: 150,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (_, row) => {
            const project = row as ProjectType;
            const clientInfo = project?.clientInfo;
            return clientInfo ? clientInfo.status : '';
        }
    },
    {
        field: 'regionName',
        headerName: 'State',
        aliasName: LangAliasEnum.STATE_REGION_LABEL,
        width: 175,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (_, row) => {
            const project = row as ProjectType;
            return project.customer.person.locality.region.name;
        }
    },
    {
        field: 'clientInfoRefundReason',
        headerName: 'Refund Reason',
        aliasName: LangAliasEnum.REFUND_REASON_COLUMN_TITLE,
        width: 175,
        align: 'left',
        headerAlign: 'center',
        valueGetter: (_, row) => {
            const project = row as ProjectType;
            const clientInfo = project?.clientInfo;
            return clientInfo?.refundReason ? clientInfo.refundReason : '';
        },
        renderCell: (params: GridRenderCellParams) => <RenderExpandableCell {...params} />
    }
] as Array<DataGridColumn>;

export default LEAD_GRID_APPROVE_COLUMNS;
