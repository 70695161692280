import { customDeepPartial } from 'global/globalFunctions';
import { object } from 'zod';
import UserSchema from './UserSchema';
import UserCompactBodySchema from './UserCompactBodySchema';

export default object({})
    .extend(
        customDeepPartial(UserSchema.shape, [
            'role',
            'phoneNumber',
            'person',
            'profile',
            'availableFunds',
            'industry',
            'products',
            'countLeadsPerDay',
            'pricePerLead',
            'isExclusive',
            'companyName',
            'person.locality.region.id',
            'person.locality.id'
        ])
    )
    .merge(UserCompactBodySchema);
