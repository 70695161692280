import { DataGridColumn } from 'global/component/CustomDataGrid/CustomDataGrid.types';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

const MEMBERS_HISTORY_GRID_COLUMNS = [
    {
        field: 'companyName',
        headerName: 'Contract',
        aliasName: LangAliasEnum.CONTRACT_COLUMN_TITLE,
        flex: 0.8,
        minWidth: 200,
        align: 'left',
        headerAlign: 'left'
    },
    {
        field: 'delivered',
        headerName: 'Delivered',
        aliasName: LangAliasEnum.DELIVERED_COLUMN_TITLE,
        flex: 0.2,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'billable',
        headerName: 'Billable',
        aliasName: LangAliasEnum.BILLABLE_COLUMN_TITLE,
        flex: 0.2,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'spent',
        headerName: 'Spent',
        aliasName: LangAliasEnum.SPENT_COLUMN_TITLE,
        flex: 0.2,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (value) => {
            const avgPrice = value as number;
            return `$${avgPrice}`;
        }
    },
    {
        field: 'returnRate',
        headerName: 'Return Rate',
        aliasName: LangAliasEnum.RETURN_RATE_COLUMN_TITLE,
        flex: 0.2,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (value) => {
            const returnRate = value as number;
            return `${returnRate}%`;
        }
    },
    {
        field: 'returned',
        headerName: 'Returned',
        aliasName: LangAliasEnum.RETURNED_COLUMN_TITLE,
        flex: 0.2,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center'
    }
] as Array<DataGridColumn>;

export default MEMBERS_HISTORY_GRID_COLUMNS;
