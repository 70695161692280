import { lazyWithRetry } from 'utils';
import { PageUrl } from 'index.types';
import { guidGenerator } from 'global/globalFunctions';
import { STATISTIC_PAGE_NAME, STATISTIC_URLS } from 'client/Statistic/index.url';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { withSecurity } from 'global/abstraction';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

const Statistic = lazyWithRetry(() => import('./Statistic'));

const STATISTIC_GUID = guidGenerator();

const StatisticUrl: PageUrl = {
    id: STATISTIC_GUID,
    name: 'Statistic',
    label: 'Statistic',
    aliasName: LangAliasEnum.STATISTIC_TITLE,
    redirectUrl: STATISTIC_URLS.MY,
    routingUrl: STATISTIC_URLS.MY,
    inMenu: true,
    role: 'all',
    Component: withSecurity(STATISTIC_PAGE_NAME.MY, Statistic),
    UrlIcon: StackedLineChartIcon,
    getGuidBold: () => STATISTIC_GUID
};

export { StatisticUrl };
