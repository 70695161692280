import { Outlet } from 'react-router';
import React from 'react';
import './Layout.scss';
import { Cookies } from 'react-cookie';
import { contextActions } from 'reduxProvider';
import { ACCESS_TOKEN, USER_TYPE, UserSecurityTypeEnum } from 'global/globalSecurity';
import { Navigate } from 'react-router-dom';
import { defaultPageUrl } from 'global/globalNavigate';

const COOKIES = new Cookies();

const Layout = () => {
    const { user } = contextActions.selector();

    const accessToken = COOKIES.get(ACCESS_TOKEN);
    const userType = COOKIES.get(USER_TYPE);
    if (accessToken && userType && user.id) {
        return <Navigate to={defaultPageUrl(userType as UserSecurityTypeEnum)} />;
    }

    return (
        <div className={'anonymous-layout'}>
            <img className={'login-logo'} alt={'brand-logo'} src={'assets/brand/brand.svg'} />
            <div className={'card'}>
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
