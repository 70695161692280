import {
    DateTimePicker,
    DateTimePickerProps,
    DateTimeValidationError,
    PickerChangeHandlerContext
} from '@mui/x-date-pickers';
import { FieldError } from 'react-hook-form';
import moment from 'moment';
import { ONLY_DATE_IN_READ_VIEW } from 'global/component/CustomDatePicker/CustomDatePicker.styles';
import React, { useMemo, useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import ErrorAdornment from 'global/component/ErrorAdornment/ErrorAdornment';
import { DateTimePickerSlotProps } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker.types';

const CustomDatePicker = React.forwardRef(
    (
        props: DateTimePickerProps<moment.Moment> & {
            error?: FieldError;
            onBlur?: () => void;
        },
        _ref
    ) => {
        const [open, setOpen] = useState<boolean>(props.open ?? false);

        const InputProps = useMemo(
            () => ({
                endAdornment: (
                    <InputAdornment position={'end'}>
                        <IconButton
                            size={'medium'}
                            aria-label={'create'}
                            onClick={() => setOpen((prev) => !prev)}
                        >
                            <InsertInvitationIcon fontSize={'inherit'} />
                        </IconButton>
                        <ErrorAdornment fieldError={props.error} withAdornment={false} />
                    </InputAdornment>
                )
            }),
            [props.error]
        );

        const slotProps = useMemo(
            (): DateTimePickerSlotProps<moment.Moment, boolean> => ({
                textField: {
                    size: 'small',
                    InputProps
                }
            }),
            [props.error, InputProps]
        );

        const handleChange = (
            value: moment.Moment | null,
            context: PickerChangeHandlerContext<DateTimeValidationError>
        ) => {
            if (props.onChange) {
                props.onChange(value, context);
            }
            if (props.onBlur) {
                props.onBlur();
            }
            setOpen(false);
        };

        const handleClose = () => {
            if (props.onClose) {
                props.onClose();
            }
            if (props.onBlur) {
                props.onBlur();
            }
            setOpen(false);
        };

        return (
            <DateTimePicker
                {...props}
                open={open}
                onChange={handleChange}
                onClose={handleClose}
                slotProps={slotProps}
                views={ONLY_DATE_IN_READ_VIEW}
            />
        );
    }
);

export default CustomDatePicker;
