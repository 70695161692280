import { array, object, z, ZodArray, ZodEffects, ZodObject, ZodOptional, ZodRawShape } from 'zod';
import { DataGridAction } from 'utils/GridColumnsService/GridColumnsService.types';
import { IndexableObject } from 'index.types';
import { GlobalPermissionsConstant } from 'global/globalSecurity';

const guidGenerator = () => {
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};

const buildQueryParams = (input: object) => {
    if (Object.keys(input).length === 0) return '';

    const data = input as {
        [key: string]: unknown;
    };
    const query = Object.keys(data).map((key) => {
        const value = data[key];
        if (typeof value === 'number') {
            return `${key}=${value}`;
        }

        if (typeof value === 'string') {
            if (value !== '*') {
                return `${key}=${value}`;
            }
        } else if (value) {
            return `${key}=${value}`;
        }

        return '';
    });

    if (query.length === 0) return '';
    return `?${query.join('&')}`;
};

const buildQueryRow = (data: object, path: string) => {
    let query = buildQueryParams(data);
    if (path.indexOf('?') >= 0) {
        query = `&${query.substring(1)}`;
    }
    return query;
};

const clearCookies = () => {
    const allCookies = document.cookie.split(';');

    for (let i = 0; i < allCookies.length; i++) {
        const currCookie = allCookies[i];
        const eqPos = currCookie.indexOf('=');
        const name = eqPos > -1 ? currCookie.substring(0, eqPos) : currCookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
};

function stringToColor(str: string) {
    let hash = 0;
    let i;

    for (i = 0; i < str.length; i += 1) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
}

function invertColor(h: string) {
    let hex = h;
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
}

const getSxColor = (name: string) => {
    const bgColor = stringToColor(name);
    return {
        style: {
            backgroundColor: bgColor,
            color: invertColor(bgColor)
        }
    };
};

const stringAvatar = (name: string) => ({
    sx: {
        bgcolor: stringToColor(name)
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
});

const customDeepPartial = <T extends ZodRawShape, K extends keyof T>(
    shape: T,
    ignoreFields: Array<string> = [],
    path: string = ''
): T => {
    const newObject: T = {} as T;
    (Object.keys(shape) as Array<K>).forEach((name: K) => {
        const nameStr = name as never as string;
        const prop = shape[name];
        const newPath = path.length === 0 ? nameStr : `${path}.${nameStr}`;
        if (prop instanceof ZodObject) {
            const obj = object({}).extend(customDeepPartial(prop.shape, ignoreFields, newPath));
            if (ignoreFields.indexOf(newPath) >= 0 || ignoreFields.indexOf(nameStr) >= 0) {
                newObject[name] = obj as never as T[K];
            } else {
                newObject[name] = obj.nullish() as never as T[K];
            }
        } else if (prop instanceof ZodArray) {
            const { element } = prop;
            let obj;
            if (element instanceof ZodObject) {
                obj = array(object({}).extend(customDeepPartial(element.shape, ignoreFields, newPath)));
            } else {
                obj = array(element);
            }
            if (ignoreFields.indexOf(newPath) >= 0 || ignoreFields.indexOf(nameStr) >= 0) {
                newObject[name] = obj as never as T[K];
            } else {
                newObject[name] = obj.nullish() as never as T[K];
            }
        } else if (prop instanceof ZodEffects) {
            const { schema, effect } = prop._def;
            if (schema instanceof ZodArray) {
                const { element } = schema;
                let obj;
                if (element instanceof ZodObject) {
                    obj = ZodEffects.create(
                        array(
                            object({}).extend(customDeepPartial(element.shape, ignoreFields, newPath))
                        ) as never as T[K],
                        effect
                    );
                } else {
                    obj = ZodEffects.create(array(element), effect);
                }
                if (ignoreFields.indexOf(newPath) >= 0 || ignoreFields.indexOf(nameStr) >= 0) {
                    newObject[name] = obj as never as T[K];
                } else {
                    newObject[name] = obj.nullish() as never as T[K];
                }
            } else if (schema instanceof ZodObject) {
                const obj = ZodEffects.create(
                    object({}).extend(customDeepPartial(schema.shape, ignoreFields, newPath)),
                    effect
                );
                if (ignoreFields.indexOf(newPath) >= 0 || ignoreFields.indexOf(nameStr) >= 0) {
                    newObject[name] = obj as never as T[K];
                } else {
                    newObject[name] = obj.nullish() as never as T[K];
                }
            } else {
                const obj = ZodEffects.create(schema, effect);
                if (ignoreFields.indexOf(newPath) >= 0 || ignoreFields.indexOf(nameStr) >= 0) {
                    newObject[name] = obj as never as T[K];
                } else {
                    newObject[name] = obj.nullish() as never as T[K];
                }
            }
        } else if (prop instanceof ZodOptional) {
            const { innerType } = prop._def;
            newObject[name] =
                innerType instanceof ZodObject
                    ? (object({})
                          .extend(customDeepPartial(innerType.shape, ignoreFields, newPath))
                          .nullish() as never as T[K])
                    : prop;
        } else if (ignoreFields.indexOf(newPath) >= 0 || ignoreFields.indexOf(nameStr) >= 0) {
            newObject[name] = prop as never as T[K];
        } else {
            newObject[name] = prop.nullish() as never as T[K];
        }
    });
    return newObject;
};

// TODO support different situations for defaults same as customDeepPartial
const getDefaults = <Schema extends z.AnyZodObject>(schema: Schema) =>
    Object.fromEntries(
        Object.entries(schema.shape).map(([key, value]) => {
            if (value instanceof z.ZodDefault) return [key, value._def.defaultValue()];
            return [key, undefined];
        })
    );

const getDefaultEnum = (value: string | undefined | null) => {
    if (value) {
        return [
            {
                name: value,
                value
            }
        ];
    }
    return [];
};

const getDefaultEnumForArray = (values: Array<string> | undefined | null) => {
    if (values) {
        return values.map((value) => ({
            value,
            name: value
        }));
    }
    return [];
};

const getDefaultEnumWithName = (name: string | undefined | null, value: string | undefined | null) => {
    if (value && name) {
        return [
            {
                name,
                value
            }
        ];
    }
    return [];
};

const identifyDataGridAction = (
    actionForEdit: DataGridAction.LOOKUP | DataGridAction.EDIT,
    secData: IndexableObject<boolean>
): Array<DataGridAction> => {
    const actions: Array<DataGridAction> = [];
    if (secData[GlobalPermissionsConstant.EDIT]) {
        actions.push(actionForEdit);
    }
    if (secData[GlobalPermissionsConstant.DELETE]) {
        actions.push(DataGridAction.DELETE);
    }
    return actions;
};

export {
    getDefaults,
    identifyDataGridAction,
    guidGenerator,
    buildQueryParams,
    clearCookies,
    stringAvatar,
    getSxColor,
    customDeepPartial,
    getDefaultEnum,
    getDefaultEnumWithName,
    getDefaultEnumForArray,
    buildQueryRow
};
