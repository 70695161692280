import 'global/component/Criteria/CriteriaForMemberHistory/CriteriaForMemberHistory.scss';
import { CriteriaBaseProps } from 'global/component/Criteria/Criteria.types';
import React, { useCallback } from 'react';
import { FormControl } from '@mui/material';
import { CriteriaMemberHistory } from 'global/component/Criteria/CriteriaForMemberHistory/CriteriaForMemberHistory.types';
import CustomDatePicker from 'global/component/CustomDatePicker/CustomDatePicker';
import moment from 'moment';
import { langModelActions } from 'reduxProvider';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';

const CriteriaForMemberHistory = ({ criteria, onCriteriaChanged }: CriteriaBaseProps) => {
    const { spaModel } = langModelActions.selector();
    const castedCriteria = criteria as CriteriaMemberHistory;

    const onFromChanged = useCallback(
        (newValue: string | undefined) => {
            const castedNewValue = newValue ? encodeURIComponent(newValue) : undefined;
            if (onCriteriaChanged && castedCriteria.fromDateAssigned !== castedNewValue) {
                onCriteriaChanged({
                    ...criteria,
                    fromDateAssigned: castedNewValue
                });
            }
        },
        [onCriteriaChanged, criteria]
    );
    const onToChanged = useCallback(
        (newValue: string | undefined) => {
            const castedNewValue = newValue ? encodeURIComponent(newValue) : undefined;
            if (onCriteriaChanged && castedCriteria.toDateAssigned !== castedNewValue) {
                onCriteriaChanged({
                    ...criteria,
                    toDateAssigned: castedNewValue
                });
            }
        },
        [onCriteriaChanged, criteria]
    );

    return (
        <>
            <FormControl fullWidth={true}>
                <CustomDatePicker
                    onChange={(val: moment.Moment | null) => onFromChanged(val?.toISOString(true))}
                    label={spaModel[LangAliasEnum.FROM_LABEL]}
                    value={
                        castedCriteria.fromDateAssigned
                            ? moment(decodeURIComponent(castedCriteria.fromDateAssigned))
                            : undefined
                    }
                />
            </FormControl>
            <FormControl fullWidth={true}>
                <CustomDatePicker
                    onChange={(val: moment.Moment | null) => onToChanged(val?.toISOString(true))}
                    label={spaModel[LangAliasEnum.TO_LABEL]}
                    value={
                        castedCriteria.toDateAssigned
                            ? moment(decodeURIComponent(castedCriteria.toDateAssigned))
                            : moment(castedCriteria.toDateAssigned)
                    }
                />
            </FormControl>
        </>
    );
};

export default CriteriaForMemberHistory;
