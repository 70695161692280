import { CriteriaLead } from 'global/component/Criteria/CriteriaForLead/CriteriaForLead.types';
import React, { useCallback, useMemo } from 'react';
import { FormControl, TextField } from '@mui/material';
import { SelectFromDict } from 'global/component';
import CustomDatePicker from 'global/component/CustomDatePicker/CustomDatePicker';
import moment from 'moment';
import { getSearchIconName } from 'global/globalRender';
import './CriteriaForLead.scss';
import { langModelActions } from 'reduxProvider';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';
import { CriteriaBaseProps } from '../Criteria.types';

const CriteriaForLead = ({ criteria, onCriteriaChanged, extra }: CriteriaBaseProps) => {
    const { spaModel } = langModelActions.selector();
    const castedCriteria = criteria as CriteriaLead;

    const onCustomerChanged = useCallback(
        (arg: React.ChangeEvent<HTMLInputElement>) => {
            const customerFullName = arg.target.value || undefined;
            if (onCriteriaChanged && castedCriteria.customerFullName !== customerFullName) {
                onCriteriaChanged({
                    ...criteria,
                    customerFullName
                });
            }
        },
        [onCriteriaChanged, criteria]
    );
    const onStateChanged = useCallback(
        (newValue: string | undefined) => {
            if (onCriteriaChanged && castedCriteria.region !== newValue) {
                onCriteriaChanged({
                    ...criteria,
                    region: newValue
                });
            }
        },
        [onCriteriaChanged, criteria]
    );
    const onIndustryChanged = useCallback(
        (newValue: string | undefined) => {
            if (onCriteriaChanged && castedCriteria.industry !== newValue) {
                onCriteriaChanged({
                    ...criteria,
                    industry: newValue
                });
            }
        },
        [onCriteriaChanged, criteria]
    );
    const onProductChanged = useCallback(
        (newValue: string | undefined) => {
            if (onCriteriaChanged && castedCriteria.product !== newValue) {
                onCriteriaChanged({
                    ...criteria,
                    product: newValue
                });
            }
        },
        [onCriteriaChanged, criteria]
    );
    const onStatusChanged = useCallback(
        (newValue: string | undefined) => {
            if (onCriteriaChanged && castedCriteria.clientStatus !== newValue) {
                onCriteriaChanged({
                    ...criteria,
                    clientStatus: newValue
                });
            }
        },
        [onCriteriaChanged, criteria]
    );
    const onFromChanged = useCallback(
        (newValue: string | undefined) => {
            const castedNewValue = newValue ? encodeURIComponent(newValue) : undefined;
            if (onCriteriaChanged && castedCriteria.from !== castedNewValue) {
                onCriteriaChanged({
                    ...criteria,
                    from: castedNewValue
                });
            }
        },
        [onCriteriaChanged, criteria]
    );
    const onToChanged = useCallback(
        (newValue: string | undefined) => {
            const castedNewValue = newValue ? encodeURIComponent(newValue) : undefined;
            if (onCriteriaChanged && castedCriteria.to !== castedNewValue) {
                onCriteriaChanged({
                    ...criteria,
                    to: castedNewValue
                });
            }
        },
        [onCriteriaChanged, criteria]
    );

    const searchIcon = useMemo(() => getSearchIconName(), []);

    return (
        <>
            <FormControl fullWidth={true}>
                <TextField
                    margin={undefined}
                    label={spaModel[LangAliasEnum.LEAD_FULL_NAME_LABEL]}
                    InputProps={searchIcon}
                    placeholder={spaModel[LangAliasEnum.SEARCH_LEAD_PLACEHOLDER]}
                    variant={'outlined'}
                    value={castedCriteria.customerFullName || ''}
                    onChange={onCustomerChanged}
                />
            </FormControl>
            <FormControl fullWidth={true}>
                <SelectFromDict
                    mode={'industry'}
                    label={spaModel[LangAliasEnum.INDUSTRY_LABEL]}
                    selectAllOption={true}
                    value={castedCriteria.industry}
                    onChange={onIndustryChanged}
                />
            </FormControl>
            <FormControl fullWidth={true}>
                <SelectFromDict
                    mode={'product'}
                    label={spaModel[LangAliasEnum.PRODUCT_LABEL]}
                    selectAllOption={true}
                    value={castedCriteria.product}
                    onChange={onProductChanged}
                />
            </FormControl>
            <FormControl fullWidth={true}>
                <SelectFromDict
                    onChange={onStatusChanged}
                    label={spaModel[LangAliasEnum.STATUS_LABEL]}
                    mode={'project_status'}
                    value={castedCriteria.clientStatus}
                    selectAllOption={true}
                    queryParams={extra}
                />
            </FormControl>
            <FormControl fullWidth={true}>
                <SelectFromDict
                    onChange={onStateChanged}
                    label={spaModel[LangAliasEnum.STATE_REGION_LABEL]}
                    mode={'region'}
                    value={castedCriteria.region}
                    selectAllOption={true}
                />
            </FormControl>
            <FormControl fullWidth={true}>
                <CustomDatePicker
                    onChange={(val: moment.Moment | null) => onFromChanged(val?.toISOString(true))}
                    label={spaModel[LangAliasEnum.FROM_LABEL]}
                    value={castedCriteria.from ? moment(decodeURIComponent(castedCriteria.from)) : undefined}
                />
            </FormControl>
            <FormControl fullWidth={true}>
                <CustomDatePicker
                    onChange={(val: moment.Moment | null) => onToChanged(val?.toISOString(true))}
                    label={spaModel[LangAliasEnum.TO_LABEL]}
                    value={
                        castedCriteria.to
                            ? moment(decodeURIComponent(castedCriteria.to))
                            : moment(castedCriteria.to)
                    }
                />
            </FormControl>
        </>
    );
};

export default CriteriaForLead;
