import { GridRenderCellParams } from '@mui/x-data-grid';
import { CustomerType } from 'pojo/customer';
import customerFunctions from 'pojo/customer/customerFunctions';
import React from 'react';
import { DataGridColumn } from 'global/component/CustomDataGrid/CustomDataGrid.types';
import CustomerInfoCell from 'utils/GridColumnsService/columns/common/CustomerInfoCell/CustomerInfoCell';
import moment from 'moment';
import { ProjectType } from 'pojo/project';
import { DATE_TIME_FORMAT } from 'global/globalFormatters';
import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';
import RenderExpandableCell from './RenderExpandableCell/RenderExpandableCell';

const LEAD_GRID_CLIENT_COLUMNS = [
    {
        field: 'customer',
        headerName: 'Lead Info',
        aliasName: LangAliasEnum.LEAD_INFO_COLUMN_TITLE,
        flex: 0.2,
        minWidth: 210,
        align: 'left',
        headerAlign: 'left',
        valueGetter: (value) => {
            const customer = value as CustomerType;
            return customerFunctions.getFullName(customer);
        },
        renderCell: (params: GridRenderCellParams) => (
            <CustomerInfoCell fullName={params.value} createdAt={params.row.createdAt} />
        )
    },
    {
        field: 'clientInfoDateAssigned',
        headerName: 'Date Assigned',
        aliasName: LangAliasEnum.DATE_ASSIGNED_COLUMN_TITLE,
        width: 150,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (_, row) => {
            const project = row as ProjectType;
            return moment(project.clientInfo.dateAssigned).format(DATE_TIME_FORMAT);
        },
        renderCell: (params: GridRenderCellParams) => {
            const momento = (params.value as string).split(' ');
            return (
                <span className={'lead-cell'}>
                    <span>{momento[0]}</span>
                    <span>
                        {momento[1]} {momento[2]}
                    </span>
                </span>
            );
        }
    },
    {
        field: 'product',
        headerName: 'Product',
        aliasName: LangAliasEnum.PRODUCT_LABEL,
        width: 150,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'customerEmail',
        headerName: 'Email',
        aliasName: LangAliasEnum.EMAIL_LABEL,
        width: 200,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (_, row) => {
            const project = row as ProjectType;
            return project.customer.person.email;
        },
        renderCell: (params: GridRenderCellParams) => <RenderExpandableCell {...params} />
    },
    {
        field: 'customerPhoneNumber',
        headerName: 'Phone',
        aliasName: LangAliasEnum.PHONE_LABEL,
        width: 150,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (_, row) => {
            const project = row as ProjectType;
            return project.customer.person.phoneNumber;
        }
    },
    {
        field: 'clientInfoStatus',
        headerName: 'Status',
        aliasName: LangAliasEnum.STATUS_LABEL,
        width: 150,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (_, row) => {
            const project = row as ProjectType;
            return project.clientInfo.status;
        }
    },
    {
        field: 'clientInfoRefundReason',
        headerName: 'Refund Reason',
        aliasName: LangAliasEnum.REFUND_REASON_COLUMN_TITLE,
        width: 175,
        align: 'left',
        headerAlign: 'center',
        valueGetter: (_, row) => {
            const project = row as ProjectType;
            return project.clientInfo.refundReason;
        },
        renderCell: (params: GridRenderCellParams) => <RenderExpandableCell {...params} />
    }
] as Array<DataGridColumn>;

export default LEAD_GRID_CLIENT_COLUMNS;
