import { LangAliasEnum } from 'reduxProvider/languageModelReducerProvider/languageModel.types';
import { lazyWithRetry } from '../utils';
import { Bundle, PageUrl } from '../index.types';
import { guidGenerator } from '../global/globalFunctions';
import { ANON_PAGES_URLS } from './index.url';

const Authenticate = lazyWithRetry(() => import('./Authenticate/Authenticate'));
const ForgetPassword = lazyWithRetry(() => import('./ForgetPassword/ForgetPassword'));

const AuthenticateUrl: PageUrl = {
    id: guidGenerator(),
    name: 'Authentication',
    aliasName: LangAliasEnum.UNUSED,
    redirectUrl: ANON_PAGES_URLS.AUTH,
    routingUrl: '',
    role: 'all',
    Component: Authenticate
};
const ForgotPwdUrl: PageUrl = {
    id: guidGenerator(),
    name: 'Forget password',
    aliasName: LangAliasEnum.UNUSED,
    redirectUrl: ANON_PAGES_URLS.FORGET_PWD,
    routingUrl: 'forgetPassword',
    role: 'all',
    Component: ForgetPassword
};
const AnonAuthGroupPages: PageUrl = {
    id: guidGenerator(),
    name: 'Anonymous pages',
    aliasName: LangAliasEnum.UNUSED,
    redirectUrl: `/${Bundle.ANONYMOUS}`,
    routingUrl: `/${Bundle.ANONYMOUS}`,
    role: 'all',
    children: [ForgotPwdUrl, AuthenticateUrl]
};

export { AnonAuthGroupPages, AuthenticateUrl, ForgotPwdUrl };
