import { DataGridActionProps } from 'utils/GridColumnsService/GridColumnsService.types';
import React from 'react';
import { Button } from '@mui/material';

const ComplexButton = ({ label, onClick, disabled, visible, color }: DataGridActionProps) => (
    <>
        {visible && (
            <Button size={'medium'} color={color || 'success'} onClick={onClick} disabled={disabled}>
                {label}
            </Button>
        )}
    </>
);

export default ComplexButton;
