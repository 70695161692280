import { COMMON_PAGES_URL } from 'common/index.url';
import { Bundle, BundleUrls } from 'index.types';
import { MEMBER_PAGES_URLS, MEMBER_PARAMS } from 'admin/Member/index.url';

export type AdminBundleUrls = BundleUrls & {
    MEMBER: {
        NEW: string;
        EDIT: string;
        LIST: string;
        MEMO: string;
    };
};

const ADMIN_PARAM = Bundle.ADMIN;

const ADMIN_PAGES_URL: AdminBundleUrls = {
    LEAD: {
        NEW: `/${ADMIN_PARAM}/${COMMON_PAGES_URL.LEAD.NEW}`,
        LIST: `/${ADMIN_PARAM}/${COMMON_PAGES_URL.LEAD.LIST}`,
        EDIT: `/${ADMIN_PARAM}/${COMMON_PAGES_URL.LEAD.EDIT}`,
        MEMO: COMMON_PAGES_URL.LEAD.MEMO
    },
    PROFILE: {
        MY: `${ADMIN_PARAM}/${COMMON_PAGES_URL.PROFILE.MY}`
    },
    MEMBER: {
        NEW: `/${ADMIN_PARAM}/${MEMBER_PAGES_URLS.NEW}`,
        EDIT: `/${ADMIN_PARAM}/${MEMBER_PAGES_URLS.EDIT}`,
        LIST: `/${ADMIN_PARAM}/${MEMBER_PAGES_URLS.LIST}`,
        MEMO: MEMBER_PARAMS.GENERAL_URL.toUpperCase()
    }
};

export { ADMIN_PAGES_URL, MEMBER_PARAMS };
